/*
* This is the global stylesheet for Nucleus.
*
* Styles added here are generic and applicable to the application as awhole.
* Any styles that may be common to both Nucleus and Proton should be added to
* theme.scss in flux-core/ui/creately-theme.
*/

@import "./flux.imports.scss";
@import "./forms.scss";
@import "./cursors.scss";
@import "./panel.scss";
@import "./uppy.scss";
@import "./color-picker.scss";
@import "./floating-container.scss";
@import "./tiptap.scss";
@import "~file-icons-js/css/style.css";
@import "~@creately/quill-mention/dist/quill.mention.css";
@import "~bootstrap/dist/css/bootstrap-grid.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~@creately/quill-image-uploader/src/quill.imageUploader.css";
@import "./tiptap.scss";

canvas {
    outline: none;
    font-kerning: none;
}
