/********************************************************************************
  This file contains app-wide global css rules that needs to be set BEFORE adding ANY
  css rules to the app.

  NOTE: SHOULD BE IMPORTED AFTER CSS RESETS TO ESTABLISH A GROUND-ZERO FOR OTHER
  STYLING RULES
*********************************************************************************/

@import '~flux-core/src/assets/fonts/Inter/inter.css';

@import '../abstracts/util-fns';

/* SET GLOBAL FONT SIZE SCALING TO 16px-based */
/***
  we set root font size to 16px because,
    * divisable by 4
    * scale is in 'rem' units
***/
html {
    font-size: 16px;
    font-family: fontFamily("sans");
}

/*** MAKE HTML ELEMENTS NAKED ***/
/* default margins removed */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

/* headings are unstyled */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/* lists are unstyled */
ol,
ul {
  margin: 0;
  padding: 0;
}

/* images are block level */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle; /* ignore warning */
}
