/* 
* This file contains all the @font-face rules needed to display fonts
* anywhere in the app.  
*/

/* Lato */
@font-face {
    font-family: 'lt_bold';
    src: url('./lato/lt-bold-webfont.woff2') format('woff2'),
         url('./lato/lt-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lt_bold_italic';
    src: url('./lato/lt-bolditalic-webfont.woff2') format('woff2'),
         url('./lato/lt-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lt_italic';
    src: url('./lato/lt-italic-webfont.woff2') format('woff2'),
         url('./lato/lt-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lt_regular';
    src: url('./lato/lt-regular-webfont.woff2') format('woff2'),
         url('./lato/lt-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lt-hairline';
    src: url('./embed/lt-hairline-webfont.woff2') format('woff2'),
         url('./embed/lt-hairline-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'lt-thinitalic';
    src: url('./embed/lt-thinitalic-webfont.woff2') format('woff2'),
         url('./embed/lt-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Noto */
@font-face {
    font-family: 'noto_bold';
    src: url('./noto/noto-bold.woff') format('woff'),
         url('./noto/noto-bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noto_bolditalic';
    src: url('./noto/noto-bolditalic.woff') format('woff'),
         url('./noto/noto-bolditalic.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noto_italic';
    src: url('./noto/noto-italic.woff') format('woff'),
         url('./noto/noto-italic.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'noto_regular';
    src: url('./noto/noto-regular.woff') format('woff'),
         url('./noto/noto-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ----------- */
/* Text Styles */
/* ----------- */

@font-face {
    font-family: 'champagne';
    src: url('./embed/champagne-webfont.woff2') format('woff2'),
         url('./embed/champagne-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'indie';
    src: url('./embed/indie-flower-webfont.woff2') format('woff2'),
         url('./embed/indie-flower-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'bebas';
    src: url('./embed/bebasneue_regular-webfont.woff2') format('woff2'),
         url('./embed/bebasneue_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'bree';
    src: url('./embed/breeserif-regular-webfont.woff2') format('woff2'),
         url('./embed/breeserif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'spartan';
    src: url('./embed/leaguespartan-bold-webfont.woff2') format('woff2'),
         url('./embed/leaguespartan-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'montserrat';
    src: url('./embed/montserrat-medium-webfont.woff2') format('woff2'),
         url('./embed/montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'open_sanscondensed';
    src: url('./embed/opensans-condlight-webfont.woff2') format('woff2'),
         url('./embed/opensans-condlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'open_sansitalic';
    src: url('./embed/opensans-italic-webfont.woff2') format('woff2'),
         url('./embed/opensans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'playfair';
    src: url('./embed/playfairdisplay-regular-webfont.woff2') format('woff2'),
         url('./embed/playfairdisplay-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'raleway';
    src: url('./embed/raleway-regular-webfont.woff2') format('woff2'),
         url('./embed/raleway-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'courier_prime';
    src: url('./embed/courier_prime-webfont.woff2') format('woff2'),
         url('./embed/courier_prime-webfont.woff') format('woff'),
         url('./embed/courier_prime-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'droid_serifregular';
    src: url('./embed/droid-serif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'abhaya_libreregular';
    src: url('./embed/abhaya-libre-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gandhi_serifregular';
    src: url('./embed/gandhi-serif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}