/*
* This file contains mixins for adding flexbox support 
* for older browsers.
* Source: https://gist.github.com/richardtorres314/26b18e12958ba418bb37993fdcbfc1bd
*/

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:    $values;
  -webkit-flex:     $values;
  -ms-flex:         $values;
  flex:             $values;
}

@mixin justify-content($justify) {
  -webkit-justify-content:  $justify;
  -moz-justify-content:     $justify;
  -ms-justify-content:      $justify;
  justify-content:          $justify;
  -ms-flex-pack:            $justify;
}

@mixin align-items($align) {
  -webkit-align-items:  $align;
  -moz-align-items:     $align;
  -ms-align-items:      $align;
  align-items:          $align;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow:  $grow;
  -moz-flex-grow:     $grow;
  -ms-flex-grow:      $grow;
  flex-grow:          $grow;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction:    $direction;
  -ms-flex-direction:     $direction;
  flex-direction:         $direction;
}

@mixin flex-shrink($shrink) {
  -webkit-flex-shrink:  $shrink;
  -moz-flex-shrink:     $shrink;
  -ms-flex-shrink:      $shrink;
  flex-shrink:          $shrink;
}

@mixin flex-basis($basis) {
  -webkit-flex-basis:  $basis;
  -moz-flex-basis:     $basis;
  -ms-flex-basis:      $basis;
  flex-basis:          $basis;
}