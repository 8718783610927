@import "util-fns";

$nu-colors: (
    "primary":          #08436B,
    "secondary":        #F6F6FA,
    "dark-blue":        #04263D,
    "light-purple":     #C3C3DE,
    "xlight-grey":      #F0F0F0,
    "light-grey":       #ececec,
    "xmedium-grey":     #dcdcdc,
    "medium-grey":      #aaaaaa,
    "grey":             #7e7e7e,
    "blueish-grey":     #F3F5FA,
    "light-nv-blue":    #627281,
    "navy-blue":        #415A71,
    "primary-blue":     #0C5E96,
    "navy-blue-off":    #414B61,
    "white":            #ffffff,
    "beige":            #f9f8f7,
    "orange":           #F6AA00,
    "light-orange":     #F5AB1C,
    "dark-orange":      #FAB638,
    "crimson":          #F36C67,
    "black":            #686868,
    "green":            #61A15E,
    "yellow":           #FFC648,
    "purple":           #5D3EAA,
    "light-red":        #F05252,
    "peachy-white":     #FAE8E5,
    "charade":          #3d4242,
    "light-green":      #DCF2EE,
    "dark-grey":        #14282E,
    "dark-red":         #D92626,
    "mlight-grey":      #CACCCE,
    "mint-cream":       #F4FCFA,
    "medium-dark-grey": #697274,
    "muted-blue":       #88A1AA,
    "grey-lightest":    #FAFAFC,
);

$nu-errors: (
    "ne-red":  #F05151,
    "ne-grey": #A0A0A0,
    "ne-blue": #06263D,
);

$nu-colormap: map-merge($nu-colors, $nu-errors);

// Color Variables
$nu-primary: map-get($nu-colormap, "primary");
$nu-secondary: map-get($nu-colormap, "secondary");
$nu-notification: map-get($nu-colormap, "crimson");
$nu-sidebar-color: map-get($nu-colormap, "dark-blue");
$nu-error: map-get($nu-colormap, "dark-red");
$nu-success: map-get($nu-colormap, "green");
$nu-warning: map-get($nu-colormap, "yellow");
$nu-dark-blue: map-get($nu-colormap, "dark-blue");
$nu-border-color: map-get($nu-colormap, "light-purple");
$nu-tooltip-color: map-get($nu-colormap, "dark-grey");

// Font Colors
$nu-primary-font-color:     $nu-dark-blue;
$nu-secondary-font-color:   map-get($nu-colormap, "grey");
$nu-form-font-color:        $nu-dark-blue;
$nu-muted-text-font-color:   map-get($nu-colormap, "muted-blue");
$nu-invalid-font-color:     $nu-error;

// Font Icon Colors
$nu-primary-ft-ic-color:    $nu-dark-blue;
$nu-secondary-ft-ic-color:  map-get($nu-colormap, "grey");
$nu-ft-ic-color-light:      map-get($nu-colormap, "xmedium-grey");

$nu-primary-ic-color:       map-get($nu-colors, "primary-blue" );

// Shadows
$nu-component-shadows: 0px 2px 2px #C3C3DE;

// Header
$nu-header-bg-color: map-get($nu-colormap, "beige");

// Borders
$nu-border-light-purple:  1px solid lighten($nu-border-color, 5%); // replace with 1px solid getColor(grey, 'x-light');
$nu-border-purple:  1px solid darken($nu-border-color, 2%);
$nu-border-orange-thin: 1px solid map-get($nu-colormap, "yellow");
$nu-border-orange-thick: 2px solid map-get($nu-colormap, "yellow");
$nu-border-light-grey: 1px solid #CCCCCC;
$nu-border-light: 0.5px solid rgba(42, 82, 94, 0.5);
$nu-border-btn-light: 0.5px solid #95A8AF;
$nu-border-btn-dark: 1px solid #08436B;

// Hovers
$nu-btn-hover: map-get($nu-colormap, "light-nv-blue");
$nu-tile-hover: map-get($nu-colormap, "mint-cream");

/*
* Disabled
*/
$nu-disabled-button:        map-get($nu-colormap, "xmedium-grey");
$nu-disabled-bg-color:    #F2F5FB;
$nu-disabled-text-color:  #9FA9BC;
$nu-disabled-border:        $nu-border-light-purple;

/*
* Backgrounds
*/
$nu-dark-background: map-get($nu-colormap, "dark-blue");
$nu-grey-background: #F6F6F9; // grey, xx-light
$nu-dark-grey-background: #E0E0E0;
$nu-alice-blue-background: #F6F8FA;
$nu-white-background: white;
$nu-lightest-grey-background: map-get($nu-colormap, "grey-lightest" );

/*
* Overlays
*/
$nu-dark-overlay: rgba(map-get($nu-colormap, "black"),0.8);

/*
* Common Loaders
*/
$nu-loader-shimmer: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
$nu-loader-bg-color: map-get($nu-colormap, "xmedium-grey");

/********************************************
*
* Creately Theme v2
* 
********************************************/

/**********
* COLORS
**********/

// Primary colors
$nu2-primary-1: $nu-dark-blue; // Primary / Blue Dark
$nu2-primary-2: #FFFFFF; // Primary / White

// Action color
$nu2-action-1: #0C5E96; // Action / Blue

// Secondary colors
$nu2-secondary-1: #FFC648; // Secondary / Yellow
$nu2-secondary-2: #F05252; // Secondary / Red

// Tertiary colors
$nu2-tertiary-1: #5C4596; // Tertiary / Purple
$nu2-tertiary-2: #6D88F0; // Tertiary / BSecondary/Red Shades/Danger/Medium Emphasislue

// Neutrals
$nu2-disabled: map-get($nu-colormap, "mlight-grey");; // Neutral / Disabled

// Neutral Solid
$nu2-solid-medium: #899092; // Neutral / Solid / Medium Emphasis 
$nu2-solid-high: #435358; // Neutral / Solid /High Emphasis 

// Neutrals - Backgrounds
$nu2-background-1: #FAFAFC; // Neutral / Background / Level 1
$nu2-background-2: #F1F4FA; // Neutral / Background / Level 2
$nu2-background-3: rgba(202, 204, 206, 0.25); // Neutral / Background / Level 3

// Neutrals - Border
$nu2-border-low: rgba(42, 82, 94, 0.05); // Neutral / Border / Low Emphasis
$nu2-border-medium: rgba(202, 204, 206, 0.5); // Neutral / Border / Medium Emphasis
$nu2-border-high: rgba(42, 82, 94, 0.3); // Neutral / Border / High Emphasis

// Neutrals - On Dark
$nu2-dark-emphasis-low: rgba(255, 255, 255, 0.3); // On Dark / Low emphasis
$nu2-dark-emphasis-medium: rgba(255, 255, 255, 0.5); // On Dark / Medium emphasis
$nu2-dark-emphasis-high: rgba(255, 255, 255, 0.7); // On Dark / High emphasis

// Neutrals - Solid
$nu2-solid-emphasis-low: #B0B4B5; // Neutral / Solid / Low Emphasis
$nu2-solid-emphasis-medium: #899092; // Neutral / Solid / Medium Emphasis

// Neutrals - Transparent
$nu2-transparent-emphasis-low: rgba(98, 104, 106, 0.5); // Neutral / Transparent / Low Emphasis
$nu2-transparent-emphasis-medium: rgba(61, 66, 66, 0.6); // Neutral / Transparent / Medium Emphasis
$nu2-transparent-emphasis-medium-high: rgba(38, 55, 59, 0.7); // Neutral / Transparent / Medium High Emphasis
$nu2-transparent-emphasis-high: rgba(20, 40, 46, 0.8); // Neutral / Transparent / High Emphasis


$nu2-emphasis-low: rgba(98, 104, 106, 0.5); // Neutral / Transparent / Low Emphasis
$nu2-emphasis-medium: rgba(61, 66, 66, 0.6); // Neutral / Transparent / Medium Emphasis
$nu2-emphasis-medium-high: rgba(38, 55, 59, 0.7); // Neutral / Transparent / Medium High Emphasis
$nu2-emphasis-high: rgba(20, 40, 46, 0.8); // Neutral / Transparent / High Emphasis
$nu2-emphasis: rgba(41, 53, 56, 0.55); // Neutral / Transparent / Medium Emphasis

// Neutrals - On Dark
$nu2-dark-emphasis-low: rgba(255, 255, 255, 0.3); // On Dark / Low emphasis
$nu2-dark-emphasis-medium: rgba(255, 255, 255, 0.5); // On Dark / Medium emphasis
$nu2-dark-emphasis-high: rgba(255, 255, 255, 0.7); // On Dark / High emphasis

// Gradients
$nu2-gradient-1: linear-gradient(270deg, $nu2-action-1 1.19%, #4F9594 99.4%); // Gradient / Green
$nu2-gradient-2: linear-gradient(270deg, $nu2-secondary-2 1.19%, #C42929 99.4%); // Gradient / Red
$nu2-gradient-3: linear-gradient(270deg, $nu2-secondary-1 1.19%, #DCA127 99.4%); // Gradient / Yellow
$nu2-gradient-4: linear-gradient(270deg, $nu2-primary-1 1.19%, #080405 99.4%); // Gradient/ Blue

// Shades
$nu2-action-1-shade-1: $nu-dark-blue; // Action / Blue Shades / Selection / High Emphasis
$nu2-action-1-shade-2: #08436B; // Action / Blue Shades / Button color
$nu2-action-1-shade-3: $nu2-action-1; // Action / Blue
$nu2-action-1-shade-4: rgba(12, 94, 150, 0.9); // Action / Blue Shades / Alpha 90
$nu2-action-1-shade-5: rgba(12, 94, 150, 0.8); // Action / Blue Shades / Alpha 80
$nu2-action-1-shade-6: rgba(12, 94, 150, 0.7); // Action / Blue Shades / Alpha 70
$nu2-action-1-shade-7: rgba(12, 94, 150, 0.5); // Action / Blue Shades / Selection / Medium Emphasis Transparent
$nu2-action-1-shade-8: rgba(12, 94, 150, 0.35); // Action / Blue Shades / Alpha 35
$nu2-action-1-shade-9: rgba(12, 94, 150, 0.2);; // Action / Blue Shades / Selection / Low Emphasis Transparent
$nu2-action-1-shade-10:  linear-gradient(0deg, rgba(12, 94, 150, 0.05), rgba(12, 94, 150, 0.05)),
    rgba(255, 255, 255, 0.65); // Action / Blue Shades / Alpha 5
$nu2-action-1-shade-11:#D5E8F4; // Action / Blue Shades / Selection / Low Emphasis
$nu2-action-1-shade-12: linear-gradient(0deg, rgba(81, 192, 170, 0.05), rgba(81, 192, 170, 0.05)), #C2F0E7; // Action / Green Shades / Button Selection / High Emphasis

$nu2-secondary-1-shade-1: #6F5425; // Secondary / Yellow Shades / Warning / High Emphasis High Emphasis
$nu2-secondary-1-shade-2: #C8931F; // Secondary / Yellow Shades / Warning / Medium Emphasis
$nu2-secondary-1-shade-3: #F39C12; // Secondary / Yellow Shades / High Emphasis
$nu2-secondary-1-shade-4: #FAB638; // Secondary / Yellow Shades / Darker
$nu2-secondary-1-shade-5: $nu2-secondary-1; // Secondary / Yellow
$nu2-secondary-1-shade-6: rgba(255, 198, 72, 0.8); // Secondary / Yellow Shades / Alpha 80
$nu2-secondary-1-shade-7: rgba(255, 198, 72, 0.6); // Secondary / Yellow Shades / Alpha 60
$nu2-secondary-1-shade-8: rgba(255, 198, 72, 0.4); // Secondary / Yellow Shades / Alpha 40
$nu2-secondary-1-shade-9: rgba(255, 198, 72, 0.2); // Secondary / Yellow Shades / Warning Background

$nu2-secondary-2-shade-1: #A02222; // Secondary / Red Shades / Danger / High Emphasis High Emphasis
$nu2-secondary-2-shade-2: #D92626; // Secondary / Red Shades/Danger / Medium Emphasis
$nu2-secondary-2-shade-3: $nu2-secondary-2; // Secondary / Red
$nu2-secondary-2-shade-4: rgba(240, 87, 82, 0.9); // Secondary / Red Shades / Alpha 90
$nu2-secondary-2-shade-5: rgba(240, 93, 82, 0.8); // Secondary / Red Shades / Alpha 80
$nu2-secondary-2-shade-6: rgba(240, 98, 82, 0.65); // Secondary / Red Shades / Alpha 65
$nu2-secondary-2-shade-7: rgba(240, 100, 82, 0.5); // Secondary / Red Shades / Alpha 50
$nu2-secondary-2-shade-8: rgba(240, 103, 82, 0.3); // Secondary / Red Shades / Alpha 30
$nu2-secondary-2-shade-9: rgba(240, 106, 82, 0.1); // Secondary / Red Shades / Danger / Background

$nu2-demo-message-background: rgba(255, 237, 198, 1);

/*** TYPOGRAPHY VARIABLES ***/
$nu2-heading-color: $nu-dark-blue;
$nu2-body-color: $nu-dark-blue;

// Font Family
$nu2-heading-font-family: fontFamily("sans");
$nu2-body-font-family: fontFamily("sans");
$nu2-title-font-family: fontFamily("sans");

$nu-primary-font-family: fontFamily("sans");
$nu-bold-font-family: fontFamily("sans");
$nu-heading-font-family: fontFamily("sans");
$nu-regular-font-family: fontFamily("sans");

// Font Sizes
$nu-tiny-font-size:     9px;
$nu-xsmall-font-size:   12px;
$nu-small-font-size:    13px;
$nu-base-font-size:     14px;
$nu-medium-font-size:   16px;
$nu-large-font-size:    18px;
$nu-mlarge-font-size:   20px; 
$nu-xlarge-font-size:   30px;

/*
* Common variables used to setup the Creately theme.
*/

// Colors used in the app are defined in a color map. To use a
// color, use map-get($map-name, color).
$fx-colors: (
    "extra-light-grey":   #f2f2f2,
    "light-grey":         #e4e4e4,
    "medium-grey":        #d8d8d8,
    "grey":             #a09f9f,
    "dark-grey":          #696969,
    "crimson":          #f05758,
    "black":            #141414,
    "yellow":           #faa72d,
    "light-yellow":       #fdc977, 
    "blue":             #1a7bb9,
    "green":            #6da067,
    "white":            #ffffff,
);

$fx-social: (
    "facebook":   #324b7f,
    "twitter":    #339dc3,
    "google":     #dc4535,
    "linkedin":   #025a84,
);

$colormap: map-merge($fx-colors, $fx-social);

$fx-font-color:                 map-get($fx-colors , "black" ) !default;
$fx-background-color:           map-get($fx-colors , "white" ) !default;

$fx-font-family-base:           Lato, Arial, Helvetica, sans-serif !default;
$fx-font-size-small:            12px !default;
$fx-font-size-medium:           14px !default;
$fx-font-size-base:             16px !default;
$fx-font-size-large:            18px !default; 

$fx-content-padding:            15px !default;
$fx-content-margin:             15px !default;

$fx-medium-icon-size:           22px !default; 
$fx-large-icon-size:            45px !default; 

$fx-border-medium-grey:         solid 1px map-get($colormap, "medium-grey");