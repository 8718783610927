/*
* This file also contains a set of generic style classes which can be used
* in any app. These classes can be extended to another class or be
* used directly in the html.
*/

@import "./mixins.scss";
@import "./variables.scss";
@import "./revamp/abstracts/variables";

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
}

h2 {
    font-size:      $fx-font-size-large;
    font-weight:    normal;
}

h3{
    font-size:      $fx-font-size-base;
    font-weight:    normal;
}

a.fx-underline {
    font-weight: bold;
    font-size:   inherit;

    &:hover {
        color:             inherit;
        text-decoration:   underline;
    }
}

a.primary[disabled], a.primary[disabled]:hover {
    background: rgba(173, 173, 173, 0.2) !important;
    color: #b9b9b9 !important;
    user-select: none;
    cursor: unset;
    pointer-events: none;
}

.fx-center-all {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
}

.fx-center-horizontal-without-flex {
    top:        0;
    left:       0;
    right:      0;
    margin:     0 auto;
    position:   absolute;
}

.fx-center-vertical {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
}

.fx-center-vertical-without-flex {
    position:   absolute;
    margin:     0 auto;
    left:       0;
	right:      0;
}

.fx-center-column {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
}

.fx-center-without-flex {
    @include transform(translate(-50%,-50%));
    left:         50%;
    top:          50%;
    position:     absolute;
}

.fx-justify-space-between {
    @include flexbox();
    @include justify-content(space-between);
}

.fx-left {
    float: left;
    text-align: left;
}

.fx-right {
    float: right;
    text-align: right;
}

.fx-text-right {
    text-align: right;
}

.fx-center {
    margin: 0 auto;
    text-align: center;
}

.fx-hidden {
    display: none !important;
}

.fx-invisible-hidden {
    position: absolute;
    visibility: hidden;
}

.fx-invisible {
    visibility: hidden;
}

.fx-round {
    @include border-radius(100%);
}

.fx-not-round {
    border-radius: 0;
}

.fx-floating {
    position: absolute;
    z-index: 1;
}

.fx-no-padding {
    padding: 0;
}

.fx-no-margin {
    margin: 0;
}

.fx-padding-4 {
    padding: 4px;
}

.fx-padding {
    padding: 10px;
}

.fx-padding-8 {
    padding: 8px;
}

.fx-padding-x-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.fx-padding-y-6 {
    padding-top:    6px;
    padding-bottom: 6px;
}

.fx-padding-16 {
    padding: 16px;
}

.fx-margin {
    margin: 10px;
}

.fx-margin-16 {
    margin: 16px;
}

.fx-margin-x {
    margin-left: 10px;
    margin-right: 10px;
}

.fx-margin-top-5 {
    margin-top: 5px;
}

.fx-margin-top-10 {
    margin-top: 10px;
}

.fx-margin-top-12 {
    margin-top: 12px;
}

.fx-margin-top-15 {
    margin-top: 15px;
}

.fx-margin-top-20 {
    margin-top: 20px;
}

.fx-margin-top-24 {
    margin-top: 24px;
}

.fx-margin-top-30 {
    margin-top: 30px;
}

.fx-margin-left-0 {
    margin-left: 0;
}

.fx-margin-left-5 {
    margin-left: 5px;
}

.fx-margin-left-10 {
    margin-left: 10px;
}

.fx-margin-left-16 {
    margin-left: 16px;
}

.fx-margin-right-5 {
    margin-right: 5px;
}

.fx-margin-right-8 {
    margin-right: 8px;
}

.fx-margin-right-10 {
    margin-right: 10px;
}

.fx-margin-right-16 {
    margin-right: 16px;
}

.fx-margin-right-50 {
    margin-right: 50px;
}

.fx-padding-top-5 {
    padding-top: 5px;
}

.fx-padding-left-5 {
    padding-left: 5px;
}

.fx-padding-left-8 {
    padding-left: 8px;
}

.fx-padding-top-10 {
    padding-top: 10px;
}

.fx-padding-top-20 {
    padding-top: 20px;
}

.fx-padding-bottom-10 {
    padding-bottom: 10px;
}

.fx-padding-bottom-20 {
    padding-bottom: 20px;
}

.fx-padding-right-16 {
    padding-right: 16px;
}

.fx-margin-bottom-5 {
    margin-bottom: 5px;
}

.fx-margin-bottom-10 {
    margin-bottom: 10px;
}

.fx-margin-bottom-15 {
    margin-bottom: 15px;
}

.fx-margin-bottom-20 {
    margin-bottom: 20px;
}

.fx-margin-bottom-25 {
    margin-bottom: 25px;
}

.fx-margin-left-25 {
    margin-left: 25px;
}

.fx-margin-bottom-30 {
    margin-bottom: 30px;
}

.fx-padding-bottom-5 {
    padding-bottom: 5px;
}

.fx-padding-bottom-10 {
    padding-bottom: 10px;
}

.fx-no-float {
    float: none;
}

.fx-ellipsis {
    overflow:       hidden;
    text-overflow:  ellipsis;
    white-space:    nowrap;
}

.fx-whitespace-nowrap {
    white-space: nowrap !important;
}

.fx-span-both {
    height: 100%;
    width:  100%;
}

.fx-span-width {
    width: 100%;
}

.fx-span-height {
    height: 100%;
}

.fx-span-height-100vh {
    height: 100vh;
}

.fx-height-auto {
    height: auto;
}

.fx-height-inherit {
    height: inherit;
}

.fx-cover {
    @extend .fx-span-both;
    position:   absolute;
    overflow:   hidden;
    z-index: 1;
}

.fx-fix-top {
    top: 0;
}

.fx-fix-bottom {
    bottom: 0;
}

.fx-fix-right {
    right: 0;
}

.fx-fix-left {
    left: 0;
}

.fx-fix-top-left {
    top: 0;
    left: 0;
}

.fx-fix-top-right {
    top: 0;
    right: 0;
}

.fx-overflow-hidden {
    overflow: hidden;
}

.fx-overflow-y {
    overflow-y: auto;
}

.fx-overflow-x {
    overflow-x: auto;
}

.fx-overflow-y-hidden {
    overflow-y: hidden;
}

.fx-overflow-x-hidden {
    overflow-x: hidden;
}


.fx-invisible-item {
    position: absolute;
    visibility: hidden;
    height: 0;
}

.fx-no-selection {
    user-select: none;
}

.fx-fill-screen-height {
    height: 100vh;
}

.fx-pointer-events-none {
    pointer-events: none;
}

.fx-pointer-events-auto {
    pointer-events: auto;
}

.fx-fade-out {
    opacity: 0.3;
}

.fx-capitalize {
    text-transform: capitalize;
}

.fx-display-inline-block {
    display: inline-block;
}

.fx-flex-expand {
    flex: 1;
}

.fx-flex {
    display: flex;
}

.fx-align-center {
    @include align-items(center);
}

.fx-justify-center {
    @include justify-content(center);
}

/**
* This is a loop which generates a class for each color defined
* in the color map.
*
* When a color class is set to an HTML element it will set the background
* or the font color based on the element's type.
*
* Usage:
* Setting a font color to a text element:
* <p class="crimson"></p>
* Setting a background color to a div element:
* <div class="blue"></div>
*/
@each $name, $value in $colormap {
    div {
        &.#{$name} {
            background-color: $value;
        }
    }

    button {
        &.#{$name} {
            background-color: lighten($value, 10%);
            color: $value;

            .nu-icon {
                color: $value;
            }
        }
    }

    p, h1, h2, h3, span, a, i, label {
        &.#{$name} {
            color: $value;
        }
    }
}

// FIXME FIX-ME Use this in Boson. @include color-class-generator($colormap) in body{}
// This mixin needs to be included in the body of each application.
@mixin color-class-generator($colormapname) {
    @each $name, $value in $colormapname {
        div {
            &.#{$name} {
                background-color: $value;
            }
        }

        button {
            &.#{$name} {
                background-color: lighten($value, 69%);
                color: lighten($value, 3%);
                border: 1px solid $value;

                .nu-icon {
                    color: $value;
                }
            }
        }

        p, h1, h2, h3, span, a, i, label {
            &.#{$name} {
                color: $value;
            }
        }
    }
}

// Styles for all the scrollbars ( perfect-scrollbar )
.ps__thumb-y, .ps__thumb-x {
    background-color : $primary !important;
    width: 4px !important;
}

.ps__rail-y, .ps__rail-x {
    background-color : unset !important;
}
