
/*
* This file contains all the styles and variables related to the
* text input and multi line text inputs in the app.
*
* Classes or variables in this file will have the prefix "text-".
*/

@import "../styles/revamp/abstracts/_variables.scss";
@import "../styles/revamp/abstracts/_util-mixins.scss";

.text-input-multilined,
.text-input {
    @include padding("2");
    @include margin-top("1");
    @include border($form-border, "px");
    width:              100%;
    outline:            none;
    white-space:        pre-wrap;
    background-color:   $form-background-color;
    border-radius:      $form-border-radius;
    user-select:        text;
}

input.text-input {
    &:focus {
        border: $form-selected-border;
    }

    &::placeholder {
        color: $form-placeholder-text-color;
    }
}

.text-input-multilined {
    &:focus {
        border: $form-selected-border;
    }

    &:empty:before {
        content:    attr(data-placeholder);
        color:      $form-placeholder-text-color;
    }
}

input.text-input,
div.text-input,
.text-input-multilined {
    cursor: text;
    color: $default-font-color;
    &:hover {
        border-color: $form-hover-border;
    }
}

.text-item-inline {
    display:        flex;
    flex-direction: row;
    align-items:    center;

    label {
        flex:           1;
        margin-right:   10px;
    }
}

.dropdown-item {
    .uneditable {
        pointer-events: none;
    }

    &:hover {
        .dropdown-selection {
            border: $form-hover-border;
        }
    }
}

.dropdown-selection {
    padding-right: 30px;
}

.nu-dropdown-container {
    position:  relative;
    display:   block;
}

.nu-dropdown-arrow {
    position:   absolute;
    right:      0;
    top:        0;
}