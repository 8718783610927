/********************************************
*
* Creately Theme 2022
* 
********************************************/
@import "../abstracts/util-fns";

// Common styles
$btn-border-radius: 6px;
$btn-border-width: 1px;
$btn-font-family: fontFamily("sans");
$btn-font-weight: 400;
$btn-box-shadow: 0px 2px 2px rgba(195, 195, 222, 0.25);

// Sizes specific styles
$btn-small-height: 32px;
$btn-medium-height: 36px;
$btn-large-height: 44px;

$btn-small-font-size: 12px;
$btn-medium-font-size: 14px;
$btn-large-font-size: 16px;

$btn-small-padding: 5px 12px;
$btn-medium-padding: 7px 14px;
$btn-large-padding: 11px 24px;
$btn-free-size-padding: 6px 8px;

[class*="btn-"] {
    border-radius:  $btn-border-radius;
    font-family:    $btn-font-family;
    font-weight:    $btn-font-weight;
    line-height:    1;
    cursor:         pointer;
}

// Create button size classes, e.g. .btn-small
@each $style, $properties in $btn-styles {
    $color: map-get($properties, "color");
    $background-color: map-get($properties, "background-color");
    $border: map-get($properties, "border");
    $hover-color: map-get($properties, "hover-color");
    $hover-background-color: map-get($properties, "hover-background-color");
    $hover-border: map-get($properties, "hover-border");
    $hover-box-shadow: map-get($properties, "hover-box-shadow");;
    $active-color: map-get($properties, "active-color");
    $active-background-color: map-get($properties, "active-background-color");
    $active-border: map-get($properties, "active-border");
    $disabled-color: map-get($properties, "disabled-color");
    $disabled-background-color: map-get($properties, "disabled-background-color");
    $disabled-border: map-get($properties, "disabled-border");

    .btn-#{$style} {
        color:              $color;
        background-color:   $background-color !important;
        border:             $border;

        &:hover {
            color:              $hover-color; 
            background-color:   $hover-background-color !important;
            box-shadow:         $hover-box-shadow;
            border:             $hover-border;
        }

        &:active,
        &.active,
        &[active] {
            color:              $active-color; 
            background-color:   $active-background-color !important;
            border:             $active-border;
        }

        &:disabled,
        &[disabled],
        &.disabled {
            color:              $disabled-color;
            background-color:   $disabled-background-color !important;
            border:             $disabled-border;
            box-shadow:         none;
            cursor:             auto;
            user-select:        none;
            svg {
                color:  $disabled-color;
            }
        }
    }
}

// Map with size name: map of button sizing properties
// Free size buttons are used for buttons that don't have a specified width.
$btn-sizes: (
    small: (
        "height": $btn-small-height,
        "font-size": $btn-small-font-size,
        "padding": $btn-small-padding
    ),
    medium: (
        "height": $btn-medium-height,
        "font-size": $btn-medium-font-size,
        "padding": $btn-medium-padding
    ),
    large: (
        "height": $btn-large-height,
        "font-size": $btn-large-font-size,
        "padding": $btn-large-padding
    ),
    "free-size": (
        "padding": $btn-free-size-padding
    ),
);


// Create button style classes, e.g. .btn-primary
@each $size, $properties in $btn-sizes {
    $height: map-get($properties, "height");
    $font-size: map-get($properties, "font-size");
    $padding: map-get($properties, "padding");

    .btn-#{$size} {
        height:     $height;
        font-size:  $font-size;
        padding:    $padding;
        background-color: transparent;
    }
}