/********************************************************************************
  This file contains existing SCSS classes related to typography with the
  adaption of mixin-tokens approach. Note the use of util-fns.scss and
  util-mixins.scss. We encourage you to adapt importing mixins and functions
  provided by design system to access design tokens when writing CSS rules.

  CREATED FOR COMPATIBILITY. WILL BE DEPRECATED LATER
  WHEN TRANSITION TO NEW TYPOGRAPHY STYLES IS COMPLETE
*********************************************************************************/

@import "../abstracts/util-fns";
@import "../abstracts/util-mixins";

// Large / Title;
.nu2-title {
    @include font("sans", "semibold", "24");
    @include leading("24");
    @include tracking("tighter");
}

// Large / Subtitle
.nu2-subtitle {
    @include font("sans", "light", "12");
    @include leading("16");
    @include tracking("tight");
}

// Headings / Heading 1
.n2-heading-1 {
    @include font("sans", "normal", "16");
    @include leading("18");
    @include tracking("tight");
}

// Headings / Heading 2
.nu2-heading-2 {
    @include font("sans", "normal", "12");
    @include leading("12");
    @include tracking("normal");
}

// Headings / Heading 3
.nu2-heading-3 {
    @include font("sans", "normal", "9");
    @include leading("11");
    @include tracking("normal");
}

// Headings / Heading 4
.nu2-heading-4 {
    @include font("sans", "semibold", "6");
    @include leading("9");
    @include tracking("tight");
}

// Headings / Heading 5
.nu2-heading-5 {
    @include font("sans", "semibold", "5");
    @include leading("6");
    @include tracking("wide");
}

// Headings / Heading 6
.nu2-heading-6 {
    @include font("sans", "normal", "4");
    @include leading("6");
    @include tracking("normal");
}

// Subheadings / Subheading 1
.nu2-subheading-1 {
    @include font("sans", "light", "8");
    @include leading("6");
    @include tracking("tighter");
}

// Subheadings / Subheading 2
.nu2-subheading-2 {
    @include font("sans", "normal", "6");
    @include leading("8");
    @include tracking("normal");
}

// Subheadings / Subheading 3
.nu2-subheading-3 {
    @include font("sans", "normal", "5");
    @include leading("8");
    @include tracking("normal");
}

// Subheadings / Subheading 4
.nu2-subheading-4 {
    @include font("sans", "normal", "4.5");
    @include leading("8");
    @include tracking("normal");
}

// Subheadings / Subheading 5
.nu2-subheading-5 {
    @include font("sans", "normal", "4");
    @include leading("8");
    @include tracking("normal");
}

// Body / Large
.nu2-body-large {
    @include font("sans-serif", "normal", "4.5");
    @include leading("5");
}

// Body / Regular
.nu2-body-regular {
    @include font("sans-serif");
}

// Body / Small
.nu2-body-small {
    @include font("sans-serif", "normal", "3.5");
    @include leading("5");
}

// Body / Smaller
.nu2-body-smaller {
    @include font("sans-serif");
    @include leading("5");
    font-size: calc(#{getScale("3")} + #{getScale("px")});
}

// Body / Tiny
.nu2-body-smallest {
    @include font("sans", "normal", "3");
    @include leading("4");
}

// Clickable / Button Large
.nu2-button-large {
    @include font("sans-serif", "semibold", "4");
    @include leading("5");
}

// Clickable / Button Regular
.nu2-button-regular {
    @include font("sans-serif", "semibold", "3.5");
    @include leading("4");
}

// Small / Caption level 1
.nu2-caption-1 {
    @include font("sans-serif", "normal", "3.5");
    @include leading("5");
    @include tracking("normal");
}

// Small / Caption level 2
.nu2-caption-2 {
    @include font("sans-serif", "bold");
    @include leading("5");
    @include tracking("normal");
    font-size: calc(#{getScale("3")} + #{getScale("px")});
}

// Small / Caption level 3
.nu2-caption-3 {
    @include font("sans-serif", "semibold");
    @include leading("5");
    @include tracking("normal");
    font-size: calc(#{getScale("3")} + #{getScale("px")});
}

// Small / Caption level 4
.nu2-caption-4 {
    @include font("sans-serif", "semibold", "3");
    @include leading("4");
    @include tracking("normal");
}

// Small / Caption level 5
.nu2-caption-5 {
    @include font("sans-serif", "semibold", "3");
    @include leading("4");
    @include tracking("normal");
    font-size: calc(#{getScale("2.5")} + #{getScale("px")});
}

// Small / Caption level 6
.nu2-caption-6 {
    @include font("sans-serif", "normal", "3");
    @include leading("4");
    @include tracking("normal");
    font-size: calc(#{getScale("2.5")} + #{getScale("px")});
}

.nu2-caption-7 {
    font-family: $nu2-body-font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
}

// Small / Overline
.nu2-overline {
    @include font("sans-serif", "semibold", "2.5");
    @include leading("4");
    @include tracking("wider");
}



/*** UNUSED - MINIMAL - TO BE REMOVED ***/
/********************************************************/
/*   below classes are used very rarely or if not ever. */
/*   potentially removed in future                      */
/********************************************************/

/****** MINIMAL - used in less than 3 places ******/
/* USED IN
    1. apps\nucleus\src\framework\ui\components\label-editable-data-item.cmp.ts
*/
// Caption / Overline
.nu2-caption-overline {
    @include font("sans-serif", "normal", "2.5");
    @include leading("4");
    @include tracking("wide");
}

/* USED IN
    1. task-library-item.cmp.html
*/
// Caption / Small / Regular
.nu2-caption-small-regular {
    @include font("sans-serif", "normal", "3");
    @include leading("4");
}

/* USED IN
    1. task-library-item.cmp.html
*/
// Caption / Large / Regular
.nu2-caption-large-regular {
    @include font("sans", "normal", "3.5");
    @include leading("4");
}

/* USED IN
    1. data-import-window.cmp.html
*/
// Caption / Large / Bold
.nu2-caption-large-bold {
    @include font("sans", "bold", "3.5");
    @include leading("5");
    @include tracking("normal");
    @include text("left");
}

/* USED IN
    1. abstract-notification.cmp.html
*/
.nu2-link {
    color: $nu2-action-1-shade-1;

    &:hover {
        color: $nu2-action-1-shade-1;
    }
}

/* USED IN
    1. quick-shape-search.cmp.html [only fontWeight is different]
*/
.nu2-body-small-semibold {
    @include font("sans", "semibold", "3");
    @include leading("4");
}
