/*
* This file contains styles for all the generic buttons in the app.
* A Nucleus button class (nu-btn-*) should be accompanied by the Flux UI's fx-button class
* and the class for the button's color.
*
* Eg: <button class="fx-button nu-btn-med primary">My Button</button>
*/

@import "../../../creately-theme/variables.scss";

$nu-btn-small-height: 30px;
$nu-btn-med-height: 35px;
$nu-btn-large-height: 40px;

$nu-btn-on-grey-color: white;
$nu-btn-on-grey-text-color: $nu-dark-blue;
$nu-btn-primary-on-grey-hover-color: $nu2-action-1-shade-11;
$nu-btn-primary-on-grey-active-color: darken($nu-btn-primary-on-grey-hover-color, 2%); 

$nu-btn-border-radius:  6px;

$nu-btns: (
    large: ( $nu-btn-large-height, $nu-large-font-size ),
    med: ( $nu-btn-med-height, $nu-base-font-size ),
    small: ( $nu-btn-small-height, $nu-base-font-size ),
);

[class*="nu-btn"] {
    border-radius:  $nu-btn-border-radius;
    padding-left:   10px;
    padding-right:  10px;
}

/*
* Generate the buttons for each size.
*/
@each $name, $properties in $nu-btns {
    .nu-btn-#{$name} {
        height:         #{nth($properties, 1)};
        font-size:      #{nth($properties, 2)};
        margin-left:    5px;
        margin-right:   5px;
        font-family:    $nu-primary-font-family;
        cursor:         pointer;
        border:         none;
        border-radius:  $nu-btn-border-radius;

        &.primary {
            &:hover:enabled {
                background-color: $nu2-action-1;
                color: white;

                .nu-icon {
                    color: white;
                }
            }

            &:active {
                background-color: map-get($nu-colormap, "primary");
            }

            &.nu-btn-icon {
                border: 0;

                &:hover,
                &:active {
                    background-color: transparent;
                }
            }
        }

        &.border-none {
            border: 0;
        }

        &.secondary {
            border: $nu-border-btn-light;
            color:  $nu-primary-font-color;

            .nu-icon {
                color: $nu2-primary-1;
            }

            &:hover:enabled {
                background-color: $nu2-primary-2;
                border: $nu-border-btn-dark;
            }

            &:active {
                background-color: $nu2-action-1-shade-11;
                border: none;
            }

            &.nu-btn-icon {
                &:hover,
                &:active {
                    background-color: transparent;
                }
            }
        }

        &.link {
            color: $nu2-action-1;
            border: none;

            .nu-icon{
                color: $nu2-action-1;
            }

            &:hover:enabled {
                color: $nu2-action-1-shade-2;
                svg {
                    color: $nu2-action-1-shade-2;
                }
            }

            &:active {
                color: $nu2-action-1;
            }
        }
        
        &:focus {
            outline: none;
        }

        &.active .nu-icon {
            color:  $nu-btn-hover;
        }

        &.nu-btn-icon {
            background: transparent;
            margin: 0;

            .nu-icon {
                color: $nu2-primary-1;
            }

            &:hover:enabled {
                .nu-icon {
                    color: $nu-primary-ic-color;
                }
            }

            &.active {
                .nu-icon {
                    color:  $nu2-action-1;
                }
            }
        }

        &.nu-btn-icon-text {
            padding-left:   5px;

            .nu-icon {
                width:          25px;
                height:         25px;
                display:        inline-block;
                vertical-align: middle;
            }

            label {
                margin-left:    5px;
                cursor:         pointer;
                user-select:    0;
                vertical-align: middle;
            }
        }

        &:disabled,
        &[disabled] {
            cursor: auto;
            background-color: $nu-disabled-bg-color;
            color: $nu-disabled-text-color;
            user-select: none;

            .nu-icon {
                color: $nu-disabled-text-color;
            }

            &.nu-btn-icon {
                background-color: transparent;

                &:hover .nu-icon,
                .nu-icon {
                    color: $nu-disabled-text-color;
                }
            }
        }

        //FIX-ME: This is to be updated along with the other styles for thematic update Sept 2022
        &.primary-2 {
            background-color: white;
            border: $nu-border-light-grey;
            &:hover { 
                background: $nu2-action-1-shade-10;  
                border: 1px solid $nu2-action-1-shade-8;
                svg {
                    color:      $nu2-action-1-shade-2;
                }
            }
        
            &:active {
            background: $nu2-action-1-shade-12;
            border: 1px solid $nu2-action-1-shade-8;
                svg {
                    color:      $nu2-action-1-shade-1;
                }
            }
        }
    }
}

.nu-btn-on-grey {
    display:            flex;
    align-items:        center;
}

/**
* Disable the item click.
*/
a.disabled {
    pointer-events: none;
    color: $nu-disabled-button;
    background-color: transparent;
}

/*
* Generate outlined buttons.
*/
@each $name, $value in $nu-colormap {
    .nu-btn-icon.#{$name} {
        .nu-icon {
            color: #{$value};
        }
    }
}

/*
* This stylesheet contains a set of generic buttons.
*
* Usage:
* Standard button:
* <button class="fx-button"></button>
*
* Icon button:
* <button class="fx-button fx-icon"></button>
*
* Floating button:
* <button class="fx-button fx-floating"></button>
* <button class="fx-button fx-floating left"></button>
* <button class="fx-button fx-floating right"></button>
*
* Circular button:
* <button class="fx-button fx-round"></button>
*/

$button-round-size: 45px;

@mixin button-color-generator( $map ) {
    @each $name, $value in $map {
        @if (lightness($value) > 50) {
            .fx-button.#{$name} {
                &:hover {
                    background-color: darken($value, 5%);
                }
    
                &:active {
                    background-color: darken($value, 10%);
                }
            }
        } @else {
            .fx-button.#{$name} {
                &:hover {
                    background-color: lighten($value, 5%);
                }
    
                &:active {
                    background-color: lighten($value, 10%);
                }
            }
        }
    
        .fx-button.fx-outline.#{$name} {
            border: 1px solid $value;
            color:  $value;
    
            &:hover {
                background-color:   $value;
                color:              white;
            }
    
            &:focus {
                background-color:   transparent;
                color:              $value;
            }
        }
    }
}

.fx-button {
    transition:         background-color 0.5s ease;
    border:             none;
    text-align:         center;
    padding-left:       5px;
    padding-right:      5px;
    cursor:             pointer;

    &.fx-block {
        border-radius:  5px;
        display:        block; 
    }

    &.fx-outline {
        background-color:   transparent;
    }

    &:focus {
        outline: none;
    }

    &.fx-icon {
        height:             100%;
        background-color:   transparent;
    }

    img {
        border: none;
    }

    &.fx-floating {
        text-align: center;

        &.top {
            top: 10px;
        }

        &.fx-right {
            right: 15px;
        } 

        &.fx-left {
            left: 15px;
        }
    }

    &.fx-round {
        width:      $button-round-size;
        height:     $button-round-size;
    }
}