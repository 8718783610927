@import "~flux-core/src/ui/creately-theme/variables.scss";

.floating-content {
    // padding: 5px;
    // background-color: $nu-tooltip-color;

    // Tooltip with shortcut and label
    .floating-label {
        margin-right: 15px;
        font-size: 14px;
    }
    .floating-shortcut {
        font-size: 12px;
        opacity: 0.5;
    }

    border-radius:      7px;
}