/*** REVAMPED VARIABLES HERE ***/

/* Possibilities
** SASS Variables
** CSS Varaibles
** SASS + CSS Variables combo
*/

/**********************************************************************/
/************************** THEME UPDATES 2022 ************************/
/**********************************************************************/

@import "./variables-compat";
@import "./util-fns";

/// Generic
$primary: getColor(primary, 'dark');
$primary-complement: getColor(white);

// Borders
$default-border-radius: 6px;
$default-border-color: getColor( grey, 'dark');
$default-data-item-border: 0.5px solid getColor(grey);

/// Status
$stat-error: getColor(red);
$stat-error-lighter: getColorOpacity(red, 'base', '10');
$stat-error-lightest: getColorOpacity(red, 'base', '5');
$stat-error-darker: getColor(red, 'dark');
$stat-warning: getColor(yellow);
$stat-success: getColor(green);

/// Font colors
$default-font-color: getColor(primary, 'x-dark');
$secondary-font-color: getColor(grey);
$disabled-font-color: getColor(grey, 'light');

// Icons 
$primary-icon-color: getColor(primary, 'dark');
$primary-disabled-icon-color: getColor(grey);
$contextual-toolbar-icon-default: getColor(grey, 'xx-dark');

/// Button colors
$primary-btn-bg: $primary;
$primary-btn-hover: getColor(primary);
$disabled-btn: getColor(grey, 'light');
$secondary-btn-bg: getColor(white);
$secondary-active-bg: getColor( primary, 'light');
$muted-btn-bg: getColor(grey, 'xx-light');

// Error/Warning Button
$status-btn-bg: getColor(white);
$error-btn-hover: $stat-error-lightest;
$error-btn-active: $stat-error-lighter;

$warning-btn-hover: getColorOpacity(yellow, 'base', '5');
$warning-btn-active: getColorOpacity(yellow, 'base', '10');

// Button borders
$secondary-btn-border:  0.5px solid $default-border-color;
// Box shadows in styles map built from the following border styles for hover
$secondary-btn-hover-border: 1px solid $primary-btn-bg;
$error-btn-border: 0.5px solid $stat-error;
$warning-btn-border: 0.5px solid $stat-warning;

// Link colors
$link-color: getColor(link);
$link-hover: getColor(primary);
$link-selected: getColor(primary, 'dark');
$link-disabled: getColor(grey, 'light'); 

// Icon colors
$disabled-icon: getColor(grey);
$icon-hover-bg: getColor(primary, 'xx-light');
$icon-active-bg: getColor( primary, 'light');

// Font colors
$primary-btn-font-color: getColor(white);
$secondary-btn-font-color: getColor(primary, 'x-dark');
$error-font-color: getColor(red, 'base');
$link-font-color: getColor(primary);
$link-hover-font-color: getColor(primary, 'dark');
$placeholder-font-color: getColor(grey, 'dark');

// Tooltips
$tooltip-bg-color:      getColor(primary, 'x-dark');
$tooltip-font-color:    getColor(white) !default;

// Background color
$default-selected-bg: getColor(primary, 'xx-light');
$default-container-bg: getColor(white);
$msg-container-bg: getColor(grey, 'xx-light');

// Border
$default-container-border: 1px solid getColorOpacity(grey, 'dark', '50');

// Separators
$separator-bg: getColor(grey, 'x-light');
$separator-border: 1px solid $separator-bg;
$separator-border-medium: 1px solid getColor(grey, 'light');

// Overlay
$dark-overlay-color: getColorOpacity(black, 'base', '80'); // FIXME: #686868

// Box Shadow
$default-box-shadow: 0px 4px 9px -2px rgba(39, 41, 50, 0.12); // #272932 FIXME
$btn-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 
                0px 1px 1px rgba(0, 0, 0, 0.05);

// Form styles
$form-border: getColor(grey, 'light');
$form-border-radius: getScale('1');
$form-selected-border:1px solid getColor(primary, 'x-light');
$form-placeholder-text-color: getColor(grey, 'dark');
$form-background-color: getColor(white);
// Form hover is at 0.5px because most borders are 0.5px. Changes to 1px causes a jump on hover.
// TODO: Work with design to create box-shadows for 1px hover borders.
$form-hover-border: getColor(primary, 'dark');


// Map with style name: map of button style properties
// Disabled button properties to be updated
// Warning/error properties also to be updated
$btn-styles: (
    primary: (
        "color": $primary-btn-font-color,
        "background-color": $primary-btn-bg,
        "border": none,
        "hover-color": $primary-btn-font-color,
        "hover-background-color": $primary-btn-hover,
        "hover-box-shadow": none,
        "active-color": $primary-btn-font-color,
        "active-background-color": $primary-btn-bg,
        "active-border": none,
        "disabled-color": $primary-btn-font-color,
        "disabled-background-color": $disabled-btn,
        "disabled-border": none
    ),
    secondary: (
        "color": $secondary-btn-font-color,
        "background-color": $secondary-btn-bg,
        "border": $secondary-btn-border,
        "hover-color": $secondary-btn-font-color,
        "hover-background-color": $secondary-btn-bg,
        "hover-box-shadow": getBoxShadowBorder( $primary-btn-bg, "px" ),
        "active-color": $secondary-btn-font-color,
        "active-background-color": $secondary-active-bg,
        "active-border": none,
        "disabled-color": $disabled-btn,
        "disabled-background-color": $secondary-btn-bg,
        "disabled-border": 0.5px solid $disabled-btn
    ),
    list-item: (
        "color": $primary,
        "background-color": $primary-complement,
        "border": none,
        "hover-color": $primary,
        "hover-background-color": getColor(primary, 'xx-light' ),
        "hover-box-shadow": none,
        "active-color": $primary,
        "active-background-color": getColor(primary, 'light' ),
        "active-border": none,
        "disabled-color": getColor(grey),
        "disabled-background-color": $primary-complement,
        "disabled-border": none
    ),
    outline-icon: ( // only used in abstract notification? to be removed. 
        "color": $primary,
        "background-color": $primary-complement,
        "border": 0.5px solid $disabled-btn,
        "hover-color": $primary-btn-hover,
        "hover-background-color": $primary-complement,
        "hover-border": 1px solid $primary,
        "hover-box-shadow": $btn-box-shadow,
        "active-color": $primary-btn-hover,
        "active-background-color": getColorOpacity(primary, 'base', '7'),
        "active-border":  none,
        "disabled-color": $disabled-btn,
        "disabled-background-color": $primary-complement,
        "disabled-border": 0.5px solid $disabled-btn
    ),
    link: (
        "color": $link-color,
        "background-color": none,
        "border": none,
        "hover-color": $link-hover,
        "hover-background-color": none,
        "hover-box-shadow": none,
        "active-color": $link-selected,
        "active-background-color": none,
        "active-border":  none,
        "disabled-color": $link-disabled,
        "disabled-background-color": none,
        "disabled-border": none
    ),
    icon: (
        "color": $primary,
        "background-color": transparent,
        "border": none,
        "hover-color": $primary,
        "hover-background-color": $icon-hover-bg,
        "hover-box-shadow": none,
        "active-color": $primary,
        "active-background-color": $icon-active-bg,
        "active-border":  none,
        "disabled-color": $disabled-icon,
        "disabled-background-color": none,
        "disabled-border": none
    ),
    warning: (
        "color": $stat-warning,
        "background-color": $status-btn-bg,
        "border": $warning-btn-border,
        "hover-color": $stat-warning,
        "hover-background-color": $warning-btn-hover,
        "hover-box-shadow": getBoxShadowBorder( $stat-warning ),
        "active-color": $stat-warning,
        "active-background-color": $status-btn-bg,
        "active-border":  $warning-btn-border,
        "disabled-color": $stat-warning,
        "disabled-background-color": $disabled-btn,
        "disabled-border": none
    ),
    error: (
        "color": $stat-error,
        "background-color": $status-btn-bg,
        "border": $error-btn-border,
        "hover-color": $stat-error,
        "hover-background-color": $error-btn-hover,
        "hover-box-shadow": getBoxShadowBorder( $stat-error ),
        "active-color": $stat-error,
        "active-background-color": $status-btn-bg,
        "active-border": $error-btn-border,
        "disabled-color": $stat-error,
        "disabled-background-color": $disabled-btn,
        "disabled-border": none
    ),
    muted: (
        "color": $secondary-font-color,
        "background-color": $muted-btn-bg,
        "border": 1px solid $muted-btn-bg,
        "hover-color": $primary-btn-hover,
        "hover-background-color": $default-selected-bg,
        "hover-box-shadow": none,
        "active-color": $primary,
        "active-background-color": $secondary-active-bg,
        "active-border": none,
        "disabled-color": $disabled-btn,
        "disabled-background-color": $msg-container-bg,
        "disabled-border": none
    )
);