/*
* This file is used to manage the z-indexes in Nucleus and Proton apps.
*/

$level-1-zindex: 1;
$level-2-zindex: 2;
$level-3-zindex: 3;
$level-4-zindex: 4;
$level-5-zindex: 10;
$level-10-zindex: 1000;
$level-11-zindex: 1001;
$level-12-zindex: 1002;
$level-13-zindex: 1003;
$level-14-zindex: 1004;
$level-15-zindex: 1005;

/* Level 1 */
$zindex-drop-down: $level-1-zindex;
$zindex-user-image: $level-1-zindex;
$zindex-shape-text-editor: $level-1-zindex;
$zindex-thumbnail-loader: $level-1-zindex;
$zindex-sidebar-btn-indicator: $level-1-zindex;
$zindex-left-sidebar-panel-container: $level-1-zindex;
$zindex-thumbnail-item-lock: $level-1-zindex;
$zindex-floating-button: $level-1-zindex;

/* Level 2 */
$zindex-floating-toolbar: $level-2-zindex;
$zindex-comments-thread-container: $level-2-zindex;
$zindex-diagram-toolbar: $level-2-zindex;
$zindex-library-preview-popover: $level-2-zindex;
$zindex-help-widget: $level-2-zindex;
$zindex-verification-banner: $level-2-zindex;
$zindex-offline-reload-banner: $level-2-zindex;
$zindex-right-sidebar: $level-2-zindex;
$zindex-global-search: $level-2-zindex;
$zindex-ai-prompt: $level-2-zindex;

/* Level 3 */
$zindex-disabled-overlay: $level-3-zindex;
$zindex-storage-exceeded-banner: $level-3-zindex;
$zindex-demo-banner: $level-3-zindex;
$zindex-shape-properties-action: $level-3-zindex;
$zindex-fab-tag-panel-expand: $level-3-zindex;

/* Level 4 */
$zindex-sidebar-expanded: $level-4-zindex;

/* Level 5 */
$zindex-abs-dropdown: $level-5-zindex;
$zindex-popover: $level-5-zindex;
$zindex-shortcut-window: $level-5-zindex;
$zindex-demo-user-message: $level-5-zindex;
$zindex-copy-paste-notitifer: $level-5-zindex;
$zindex-left-sidebar-buttons-container: $level-5-zindex;
$zindex-global-notification-panel: $level-5-zindex;
$zindex-notes-toolbar: $level-5-zindex;

/* Level 10 */
$zindex-main-loader: $level-13-zindex;
$zindex-modal-window: $level-10-zindex;
$zindex-image-import-window: $level-10-zindex;
$zindex-plans-window: $level-10-zindex;

/* Level 11 */
$zindex-dialog-box: $level-11-zindex;
$zindex-notification: $level-11-zindex;
$zindex-weblinks-viewer: $level-11-zindex;
$zindex-lite-plan-window: $level-11-zindex;
$zindex-demo-email-verify-window: $level-11-zindex;
$zindex-tooltip: $level-11-zindex;

/* Level 13 */
$zindex-auth-window: $level-13-zindex;

/* Level 15 */
$zindex-upgrade-window: $level-15-zindex;
