/*
* This file contains sass mixins which lets you group CSS declarations.
* Use @include followed by the mixin name like in the example below to
* make use of a mixin;
* @include border-radius(20px);
*/

/*
* This mixin is used to truncate text and add an 
* ellipsis to the end.
*/

@import "./flexbox-mixins.scss";

@mixin truncate($truncation-boundary) {
    max-width:      $truncation-boundary;
    white-space:    nowrap;
    overflow:       hidden;
    text-overflow:  ellipsis;
}

/*
* This mixin is used to set transitions to
* a class.
*/
@mixin transition($args) {
  -webkit-transition:   $args;
  -moz-transition:      $args;
  -ms-transition:       $args;
  -o-transition:        $args;
  transition:           $args;
}

/*
* A generic mixin to set transform.
*/
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

/*
* A mixin to set an animation
*/
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

/*
* This mixin is used to set the border radius to
* an element.
*/
@mixin border-radius($radius) {
  -moz-border-radius:       $radius;
  -webkit-border-radius:    $radius;
  -ms-border-radius:        $radius;
  border-radius:            $radius;
}

/*
* This mixin is used to set the backdrop filter to
* an element.
*/
@mixin backdrop-filter($blur) {
  backdrop-filter:            blur($blur);
  -webkit-backdrop-filter:    blur($blur);
}

/*
* This mixin is used to set the border radius to
* an element.
*/
@mixin user-select($value) {
  -webkit-user-select:  $value; /* Chrome/Safari */        
  -moz-user-select:     $value; /* Firefox */
  -ms-user-select:      $value; /* IE10+ */
  -o-user-select:       $value;
  user-select:          $value;
}

/*
* A mixin to return the rgba value when the color
* and the alpha value is given.
*/
@function color-opacity($key, $opacity) {
    $value: map-get($fx-colors, $key);
    $value: rgba($value, $opacity);
    @return $value;
}

/*
* A mixin to vertically center elements when the
* height is known.
*/
@mixin vcenter($width, $height) {
  width:        $width;
  height:       $height;
  line-height:  $height;
}

/*
* A mixin which allows you to create a triangle
* shape when the point direction, size and color is defined.
* Use case: As a tooltip's pointer.
*/
@mixin create-triangle($point-direction, $border-size, $border-color) {
    width:          0;
    height:         0;

    @if ($point-direction == 'down') {
      border-top:     $border-size solid $border-color;
      border-left:    $border-size solid transparent;
      border-right:   $border-size solid transparent;
    } 

    @else if ($point-direction == 'up') {
      border-bottom:  $border-size solid $border-color;
      border-left:    $border-size solid transparent;
      border-right:   $border-size solid transparent;
    }

    @else if ($point-direction == 'left') {
      border-right:   $border-size solid $border-color;
      border-top:     $border-size solid transparent;
      border-bottom:  $border-size solid transparent;
    }

    @else if ($point-direction == 'right') {
      border-left:    $border-size solid $border-color;
      border-top:     $border-size solid transparent;
      border-bottom:  $border-size solid transparent;
    }
    
    @else if ($point-direction == 'bottom-right') {
      border-top:     $border-size solid transparent;
      border-bottom:  $border-size solid $border-color;
      border-left:    $border-size solid transparent;
      border-right:   $border-size solid $border-color;
  }
}