/*
* This file contains styles which are specific to the color picker component.
* All overrides should be nested under the nu-color-picker selector.
*/

color-picker {
    .color-picker {
        border: $nu-canvas-floats-border;
        border-radius: 4px;

        .hsla-text .box input, 
        .rgba-text .box input, 
        .value-text .box input,
        .hex-text .box input {
            border: $nu-border-light-purple;
        }

        .arrow.arrow-left-top {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) map-get($nu-colormap, "light-grey");
        }

        .selected-color {
            border: $nu-border-light-purple;
        }

        .cursor {
            box-shadow: 1px 1px 1px 0px rgba(#6E6E6E, 0.5);
            border: 2px white solid;
        }
    }
}
