/*
* This file contains styles which are common to loaders
* in Creately apps.
* Classes or variables in this file will have the prefix "nu-loader-shimmer".
*/

@import "./variables.scss";

.nu-loader-shimmer {
    width:                      inherit;
    height:                     inherit;
    top:                        0;
    left:                       0;
    background:                 $nu-loader-bg-color;
    background:                 $nu-loader-shimmer;
    background-size:            300px 75px;
    animation-duration:         2s;
    animation-fill-mode:        forwards;
    animation-iteration-count:  infinite;
    animation-name:             shimmer;
    animation-timing-function:  linear;
 }
 @keyframes shimmer{
    0%{
        background-position: -80px 0
    }
    100%{
        background-position: 80px 0
    }
 } 