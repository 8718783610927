/**
* This file contains styles which are specific to the Flux UI
* tooltip.
*
* Any class or variable declared in this file will have the prefix "tooltip-".
*/

@import "../styles/flux.ui.scss";
@import "../creately-theme/variables.scss";

$tooltip-max-width:     250px !default;
$tooltip-bg-color:      rgb(184, 184, 184);
$tooltip-font-color:    white !default;
$tooltip-border-radius: 4px !default;

$tooltip-arrow-size:    5px;

.floating {
    position:           absolute;
    z-index:            $zindex-tooltip;
    max-width:          $tooltip-max-width;
    // background-color:   $tooltip-bg-color;
    border-radius:      $tooltip-border-radius;
    box-shadow: 0px 1px 6px -3px rgb(0 0 0 / 68%);
    // color:              $tooltip-font-color;
    // padding:            10px;

    &> * {
        display: block;
        // padding: 8px;
    }
}

$tooltip-arrows: (
    bottom: up,
    top: down,
    left: right,
    right: left,
);

.floating-arrow {
    position: absolute;
    z-index:  $zindex-tooltip;

    @each $name, $direction in $tooltip-arrows {
        &.#{$name} {
            @include create-triangle( #{$direction}, $tooltip-arrow-size, $tooltip-bg-color );
        }
    }
}

.floating-header {
    font-size: 14px;
}

.floating-body {
    font-size: 12px;
}

// Overrides the default tooltip styles.