/*
* This file contains animation classes from animate.css
* https://daneden.github.io/animate.css/
*/

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }

  @-webkit-keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }
  
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  .animated.delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  .animated.delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  
  .animated.delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  
  .animated.delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }
  
  .animated.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  }
  
  .animated.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }
  
  .animated.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }
  
  .animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .animated.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
  }
  
  @media (prefers-reduced-motion) {
    .animated {
      -webkit-animation: unset !important;
      animation: unset !important;
      -webkit-transition: none !important;
      transition: none !important;
    }
  }