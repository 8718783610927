.fx-text-reset {
    * {
        font-family: initial;
        font-size: initial;
        color: initial;
        font : initial;
        font-style : initial;
        font-variant : initial;
        font-weight : initial;
        letter-spacing : initial;
        line-height : initial;
        text-align : initial;
        text-align-last : initial;
        text-decoration : initial;
        text-decoration-line : initial;
        text-decoration-style : initial;
        text-indent : initial;
        text-shadow : initial;
        text-transform : initial;
        vertical-align : initial;
        white-space : initial;
        word-spacing : initial;
        -webkit-font-smoothing: initial;
    }

    i, em {
        font-style: italic;
    }

    sup {
        vertical-align: super;
        font-size: smaller;
    }

    sub {
        vertical-align: sub;
        font-size: smaller;
    }

    b {
        font-weight: bold;
    }

    s, strike, del {
        text-decoration: line-through;
    }

}
