/*
* This file contains all the cursors that are used in the app.
* All cursor classes will start with the prefix 'cursor-'.
*
* A custom cursor should always have the positioning and fall back cursor
* specified.
*/

.cursor-rotate {
    cursor: url('/assets/cursors/rotate.svg') 11.575 9.79, auto;
}

.cursor-pointer {
    cursor: pointer;
}