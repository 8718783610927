/*
* This style sheet contains styles which are common
* to all panels in the right side bar.
*
* Classes or variables in this file are prefixed with
* 'panel-'
*
* Classes in this file are used inside each panel component. 
* Therefore this should be imported globally in the styles.scss.
*/

@import "~flux-core/src/ui/creately-theme/variables.scss";
@import "~flux-core/src/ui/styles/revamp/abstracts/_variables.scss";
@import "~flux-core/src/ui/styles/revamp/abstracts/_util-mixins.scss";

$panel-gap-between-items: 20px;
$panel-gap-between-sections: 45px;
$panel-padding: 16px;

.panel-component {
    height: inherit;
    display: block;
}

.panel-container {
    padding-left:   $panel-padding;
    padding-right:   $panel-padding;
    height:         100%;
}

.panel-heading {
    font-size:      $nu-large-font-size;
    margin-bottom:  $panel-gap-between-items;
    padding-top:    $panel-padding;
}

.panel-sub-heading {
    margin-bottom:  $panel-gap-between-items;
}

.panel-section {
    transition: margin-bottom 0.3s ease-in-out;
    margin-bottom: $panel-gap-between-sections;
}

.panel-item {
    margin-bottom: $panel-gap-between-items;

    label {
        color: $default-font-color;
    }
}

.panel-multi-dropdown-container {
    width:      100%;
    display:    flex;

    &> :last-child {
        margin-left:   10px;
    }

    .expand {
        width: 60%;
        float: left;
    }

    .shrink {
        width:  calc(40% - 10px);
        float:  left;
    }
}


