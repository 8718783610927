/*
* This file contains generic variables specific to nucleus and proton which
* can be imported into any of it's components.
*
* All variables should have the prefix "$nu-" to avoid conflicts
* with any vendor variables in future.
*/

@import "./zindex.scss";

@import "../styles/revamp/abstracts/variables";
@import "../styles/revamp/abstracts/variables-compat";

$nu-errors: (
    "ne-red":  #F05151,
    "ne-grey": #A0A0A0,
    "ne-blue": #06263D,
);

$nu-colormap: map-merge($nu-colors, $nu-errors);

// Color Variables
$nu-primary: map-get($nu-colormap, "primary");
$nu-secondary: map-get($nu-colormap, "secondary");
$nu-notification: map-get($nu-colormap, "crimson");
$nu-sidebar-color: map-get($nu-colormap, "dark-blue");
$nu-error: map-get($nu-colormap, "dark-red");
$nu-success: map-get($nu-colormap, "green");
$nu-warning: map-get($nu-colormap, "yellow");
$nu-dark-blue: map-get($nu-colormap, "dark-blue");
$nu-border-color: map-get($nu-colormap, "light-purple");
$nu-tooltip-color: map-get($nu-colormap, "dark-grey");

// Font Family
$nu-primary-font-family: 'Open Sans', sans-serif;
$nu-bold-font-family: 'lt_bold', $nu-primary-font-family;
$nu-heading-font-family: 'Open Sans', sans-serif;
$nu-regular-font-family: 'lt_regular', $nu-primary-font-family;

// Font Colors
$nu-primary-font-color:     $nu-dark-blue;
$nu-secondary-font-color:   map-get($nu-colormap, "grey");
$nu-form-font-color:        $nu-dark-blue;
$nu-invalid-font-color:     $nu-error;

// Font Sizes
$nu-tiny-font-size:     9px;
$nu-xsmall-font-size:   12px;
$nu-small-font-size:    13px;
$nu-base-font-size:     14px;
$nu-medium-font-size:   16px;
$nu-large-font-size:    18px;
$nu-mlarge-font-size:   20px; 
$nu-xlarge-font-size:   30px;

$nu-window-heading:     $nu-mlarge-font-size;

// Font Icon Colors
$nu-primary-ft-ic-color:    $nu-dark-blue;
$nu-secondary-ft-ic-color:  map-get($nu-colormap, "grey");
$nu-ft-ic-color-light:      map-get($nu-colormap, "xmedium-grey");

// Shadows
$nu-component-shadows: 0px 2px 2px #C3C3DE;

// Header
$nu-header-bg-color: map-get($nu-colormap, "beige");

// Borders
$nu-border-light-purple:  1px solid lighten($nu-border-color, 5%);
$nu-border-purple:  1px solid darken($nu-border-color, 2%);
$nu-border-orange-thin: 1px solid map-get($nu-colormap, "yellow");
$nu-border-orange-thick: 2px solid map-get($nu-colormap, "yellow");

// Hovers
$nu-btn-hover: map-get($nu-colormap, "green");
$nu-tile-hover: map-get($nu-colormap, "mint-cream");

// User Images
$nu-user-img-small: 25px;
$nu-user-img-large: 50px;

/*
* Canvas Floats 
* Canvas floats are ui elements that float on top of the canvas.
* Ex: Contextual menu, text TB, contextual TB, plus create button.
*/
$nu-canvas-floats-bg:              white;
$nu-canvas-floats-bg-dark:         $nu-sidebar-color;
$nu-canvas-floats-border:          $nu-border-light-purple;
$nu-canvas-floats-shadow:          $nu-component-shadows;
$nu-canvas-floats-border-radius:   4px;
$nu-canvas-floats-height:          45px;

/*
* Payment Failed Banner
*/
$nu-payment-fail-banner-bg-color: map-get($nu-colormap, "light-red");
$nu-payment-fail-banner-font-color: map-get($nu-colormap, "peachy-white");

/*
* Windows and Popups
*/
$nu-border-radius-popups: 4px;

/*
* Disabled
*/
$nu-disabled-button:        map-get($nu-colormap, "xmedium-grey");
$nu-disabled-bg-color:    #F2F5FB;
$nu-disabled-text-color:  #9FA9BC;
$nu-disabled-border:        $nu-border-light-purple;

/*
* Backgrounds
*/
$nu-dark-background: map-get($nu-colormap, "dark-blue");
$nu-grey-background: #F6F6F9;
$nu-dark-grey-background: #E0E0E0;
$nu-alice-blue-background: #F6F8FA;

/*
* Overlays
*/
$nu-dark-overlay: rgba(map-get($nu-colormap, "black"),0.8);

/*
* Common Loaders
*/
$nu-loader-shimmer: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
$nu-loader-bg-color: map-get($nu-colormap, "xmedium-grey");

/*
* Right sidebar
*/
$nu-right-sidebar-width: 320px;

/*
* Left sidebar
*/
$nu-left-sidebar-width: 310px; // Current width of the left side bar, since its repeated in many place we have to standardize it
$nu-left-sidebar-expanded-width: 890px;
$nu-left-sidebar-fullscreen-width: 100%;
/*
* Modal Windows
*/
$nu-modal-window-heading: $nu-large-font-size;

/*
* Forms
*/
$nu-form-border-radius: 4px;
$nu-form-background-color: white;
$nu-form-border: $nu-border-light-purple;
$nu-form-text-color: $nu-primary-font-color;
$nu-form-hover-border: 1px solid #80C6B9;
$nu-form-selected-border: 1px solid #80C6B9;
$nu-form-error-border: 1px solid #F29A9A;
$nu-form-placeholder-text-color: #9AA0A9;


/********************************************
*
* Creately Theme v2
* 
********************************************/

/**********
* COLORS
**********/

// Primary colors
$nu2-primary-1: #04263D; // Primary / Blue Dark
$nu2-primary-2: #FFFFFF; // Primary / White

// Action color
$nu2-action-1: #51C0AA; // Action / Green

// Secondary colors
$nu2-secondary-1: #FFC648; // Secondary / Yellow
$nu2-secondary-2: #F05252; // Secondary / Red

// Tertiary colors
$nu2-tertiary-1: #5C4596; // Tertiary / Purple
$nu2-tertiary-2: #6D88F0; // Tertiary / BSecondary/Red Shades/Danger/Medium Emphasislue

// Neutrals
$nu2-disabled: #CACCCE; // Neutral / Disabled

// Neutral Solid
$nu2-solid-medium: #899092; // Neutral / Solid / Medium Emphasis 
$nu2-solid-high: #435358; // Neutral / Solid /High Emphasis 

// Neutrals - Backgrounds
$nu2-background-1: #FAFAFC; // Neutral / Background / Level 1
$nu2-background-2: #F1F4FA; // Neutral / Background / Level 2
$nu2-background-3: rgba(202, 204, 206, 0.25); // Neutral / Background / Level 3

// Neutrals - Border
$nu2-border-low: rgba(42, 82, 94, 0.05); // Neutral / Border / Low Emphasis
$nu2-border-medium: rgba(202, 204, 206, 0.5); // Neutral / Border / Medium Emphasis
$nu2-border-high: rgba(42, 82, 94, 0.3); // Neutral / Border / High Emphasis

// Neutrals - On Dark
$nu2-dark-emphasis-low: rgba(255, 255, 255, 0.3); // On Dark / Low emphasis
$nu2-dark-emphasis-medium: rgba(255, 255, 255, 0.5); // On Dark / Medium emphasis
$nu2-dark-emphasis-high: rgba(255, 255, 255, 0.7); // On Dark / High emphasis

// Neutrals - Solid
$nu2-solid-emphasis-low: #B0B4B5; // Neutral / Solid / Low Emphasis
$nu2-solid-emphasis-medium: #899092; // Neutral / Solid / Medium Emphasis

// Neutrals - Transparent
$nu2-transparent-emphasis-low: rgba(98, 104, 106, 0.5); // Neutral / Transparent / Low Emphasis
$nu2-transparent-emphasis-medium: rgba(61, 66, 66, 0.6); // Neutral / Transparent / Medium Emphasis
$nu2-transparent-emphasis-medium-high: rgba(38, 55, 59, 0.7); // Neutral / Transparent / Medium High Emphasis
$nu2-transparent-emphasis-high: rgba(20, 40, 46, 0.8); // Neutral / Transparent / High Emphasis


$nu2-emphasis-low: rgba(98, 104, 106, 0.5); // Neutral / Transparent / Low Emphasis
$nu2-emphasis-medium: rgba(61, 66, 66, 0.6); // Neutral / Transparent / Medium Emphasis
$nu2-emphasis-medium-high: rgba(38, 55, 59, 0.7); // Neutral / Transparent / Medium High Emphasis
$nu2-emphasis-high: rgba(20, 40, 46, 0.8); // Neutral / Transparent / High Emphasis
$nu2-emphasis: rgba(41, 53, 56, 0.55); // Neutral / Transparent / Medium Emphasis

// Neutrals - On Dark
$nu2-dark-emphasis-low: rgba(255, 255, 255, 0.3); // On Dark / Low emphasis
$nu2-dark-emphasis-medium: rgba(255, 255, 255, 0.5); // On Dark / Medium emphasis
$nu2-dark-emphasis-high: rgba(255, 255, 255, 0.7); // On Dark / High emphasis

// Gradients
$nu2-gradient-1: linear-gradient(270deg, $nu2-action-1 1.19%, #4F9594 99.4%); // Gradient / Green
$nu2-gradient-2: linear-gradient(270deg, $nu2-secondary-2 1.19%, #C42929 99.4%); // Gradient / Red
$nu2-gradient-3: linear-gradient(270deg, $nu2-secondary-1 1.19%, #DCA127 99.4%); // Gradient / Yellow
$nu2-gradient-4: linear-gradient(270deg, $nu2-primary-1 1.19%, #080405 99.4%); // Gradient/ Blue

// Shades
$nu2-action-1-shade-1: #195249; // Action / Green Shades / Selection / High Emphasis
$nu2-action-1-shade-2: #29947F; // Action / Green Shades / Button color
$nu2-action-1-shade-3: $nu2-action-1; // Action / Green
$nu2-action-1-shade-4: rgba(81, 192, 170, 0.9); // Action / Green Shades / Alpha 90
$nu2-action-1-shade-5: rgba(81, 192, 170, 0.8); // Action / Green Shades / Alpha 80
$nu2-action-1-shade-6: rgba(81, 192, 170, 0.7); // Action / Green Shades / Alpha 70
$nu2-action-1-shade-7: rgba(81, 192, 170, 0.5); // Action / Green Shades / Selection / Medium Emphasis Transparent
$nu2-action-1-shade-8: rgba(81, 192, 170, 0.35); // Action / Green Shades / Alpha 35
$nu2-action-1-shade-9: rgba(81, 192, 170, 0.2);; // Action / Green Shades / Selection / Low Emphasis Transparent
$nu2-action-1-shade-10:  linear-gradient(0deg, rgba(81, 192, 170, 0.05), rgba(81, 192, 170, 0.05)),
    rgba(255, 255, 255, 0.65); // Action / Green Shades / Alpha 5
$nu2-action-1-shade-11: #DCF2EE; // Action / Green Shades / Selection / Low Emphasis

$nu2-secondary-1-shade-1: #6F5425; // Secondary / Yellow Shades / Warning / High Emphasis High Emphasis
$nu2-secondary-1-shade-2: #C8931F; // Secondary / Yellow Shades / Warning / Medium Emphasis
$nu2-secondary-1-shade-3: #F39C12; // Secondary / Yellow Shades / High Emphasis
$nu2-secondary-1-shade-4: #FAB638; // Secondary / Yellow Shades / Darker
$nu2-secondary-1-shade-5: $nu2-secondary-1; // Secondary / Yellow
$nu2-secondary-1-shade-6: rgba(255, 198, 72, 0.8); // Secondary / Yellow Shades / Alpha 80
$nu2-secondary-1-shade-7: rgba(255, 198, 72, 0.6); // Secondary / Yellow Shades / Alpha 60
$nu2-secondary-1-shade-8: rgba(255, 198, 72, 0.4); // Secondary / Yellow Shades / Alpha 40
$nu2-secondary-1-shade-9: rgba(255, 198, 72, 0.2); // Secondary / Yellow Shades / Warning Background

$nu2-secondary-2-shade-1: #A02222; // Secondary / Red Shades / Danger / High Emphasis High Emphasis
$nu2-secondary-2-shade-2: #D92626; // Secondary / Red Shades/Danger / Medium Emphasis
$nu2-secondary-2-shade-3: $nu2-secondary-2; // Secondary / Red
$nu2-secondary-2-shade-4: rgba(240, 87, 82, 0.9); // Secondary / Red Shades / Alpha 90
$nu2-secondary-2-shade-5: rgba(240, 93, 82, 0.8); // Secondary / Red Shades / Alpha 80
$nu2-secondary-2-shade-6: rgba(240, 98, 82, 0.65); // Secondary / Red Shades / Alpha 65
$nu2-secondary-2-shade-7: rgba(240, 100, 82, 0.5); // Secondary / Red Shades / Alpha 50
$nu2-secondary-2-shade-8: rgba(240, 103, 82, 0.3); // Secondary / Red Shades / Alpha 30
$nu2-secondary-2-shade-9: rgba(240, 106, 82, 0.1); // Secondary / Red Shades / Danger / Background

$nu2-demo-message-background: rgba(255, 237, 198, 1);
$color-danger-lighter: #FDE3E3;
$color-danger-darkest: #510606;
$color-link: #1381CD;

/**************
* TYPOGRAPHY
**************/

$nu2-heading-font-family: 'Montserrat', sans-serif;
$nu2-body-font-family: 'Open Sans', sans-serif;
$nu2-title-font-family: 'Lato', sans-serif;

$nu2-heading-color: $nu2-primary-1;
$nu2-body-color: $nu2-primary-1;

/***********
* BUTTONS
***********/

// Common styles
$nu2-btn-border-radius: 4px;
$nu2-btn-border-width: 1px;
$nu2-btn-font-family: $nu2-body-font-family;
$nu2-btn-font-weight: 600;
$nu2-button-box-shadow: 0px 2px 2px rgba(195, 195, 222, 0.25);

// Sizes specific styles
$nu2-btn-small-height: 30px;
$nu2-btn-medium-height: 36px;
$nu2-btn-large-height: 44px;

$nu2-btn-small-font-size: 14px;
$nu2-btn-medium-font-size: 14px;
$nu2-btn-large-font-size: 16px;

$nu2-btn-small-padding: 7px 16px;
$nu2-btn-medium-padding: 10px 32px;
$nu2-btn-large-padding: 12px 40px;

// Map with size name: map of button sizing properties
$nu2-btn-sizes: (
    small: (
        "height": $nu2-btn-small-height,
        "font-size": $nu2-btn-small-font-size,
        "padding": $nu2-btn-small-padding
    ),
    medium: (
        "height": $nu2-btn-medium-height,
        "font-size": $nu2-btn-medium-font-size,
        "padding": $nu2-btn-medium-padding
    ),
    large: (
        "height": $nu2-btn-large-height,
        "font-size": $nu2-btn-large-font-size,
        "padding": $nu2-btn-large-padding
    ),
);

// Map with style name: map of button style properties
$nu2-btn-styles: (
    primary: (
        "color": $nu2-primary-2,
        "background-color": $nu2-action-1-shade-2,
        "border-color": $nu2-action-1-shade-2,
        "hover-color": $nu2-primary-2,
        "hover-background-color": $nu2-action-1,
        "hover-border-color": $nu2-action-1,
        "disabled-color": $nu2-transparent-emphasis-low,
        "disabled-background-color": $nu2-disabled,
        "disabled-border-color": $nu2-disabled
    ),
    secondary: (
        "color": $nu2-action-1-shade-1,
        "background-color": $nu2-action-1-shade-9,
        "border-color": $nu2-action-1-shade-1,
        "hover-color": $nu2-primary-2,
        "hover-background-color": $nu2-action-1,
        "hover-border-color": $nu2-action-1,
        "disabled-color": $nu2-transparent-emphasis-low,
        "disabled-background-color": $nu2-disabled,
        "disabled-border-color": $nu2-disabled
    ),
    outline: (
        "color": $nu2-primary-1,
        "background-color": $nu2-primary-2,
        "border-color": $nu2-border-high,
        "hover-color": $nu2-primary-2,
        "hover-background-color": $nu2-action-1,
        "hover-border-color": $nu2-action-1,
        "disabled-color": $nu2-transparent-emphasis-low,
        "disabled-background-color": $nu2-primary-2,
        "disabled-border-color": $nu2-disabled
    ),
    warning: (
        "color": $nu2-secondary-1-shade-1,
        "background-color": $nu2-secondary-1-shade-9,
        "border-color": $nu2-secondary-1-shade-2,
        "hover-color": $nu2-secondary-1-shade-1,
        "hover-background-color": $nu2-secondary-1-shade-8,
        "hover-border-color": $nu2-secondary-1-shade-2,
        "disabled-color": $nu2-transparent-emphasis-low,
        "disabled-background-color": $nu2-disabled,
        "disabled-border-color": $nu2-disabled
    ),
    error: (
        "color": $nu2-secondary-2-shade-1,
        "background-color": $nu2-secondary-2-shade-9,
        "border-color": $nu2-secondary-2-shade-2,
        "hover-color": $nu2-secondary-2-shade-1,
        "hover-background-color": $nu2-secondary-2-shade-8,
        "hover-border-color": $nu2-secondary-2-shade-2,
        "disabled-color": $nu2-transparent-emphasis-low,
        "disabled-background-color": $nu2-disabled,
        "disabled-border-color": $nu2-disabled
    )
);
