/*
* This file contains styles which are common to all form controls in the app.
*
* A form is wrapped with the "nu-form" class. A "nu-form-section" can have multiple
* "nu-form-items". The basic structure for a nu-form goes:-
*
* <div class="nu-form">
*   <div class="nu-form-section">
*       <div class="nu-form-item">
*           <label class="nu-form-label"></label>
*           <div class="nu-form-field"><input type="text"></div>
*       </div>
*       <div class="nu-form-item">
*           <label class="nu-form-label"></label>
*           <div class="nu-form-field"><input type="text"></div>
*       </div>
*   </div>
* </div>
*/

@import "~flux-core/src/ui/creately-theme/variables.scss";
@import "~flux-core/src/ui/styles/revamp/abstracts/variables";

$nu-form-checkbox-size: 20px;

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
    color: $form-placeholder-text-color;
}

input,
textarea {
    font-size:      $nu-base-font-size;
    color:          $nu-form-font-color;
    font-family:    $nu-primary-font-family;

    &:focus {
        outline: none;
    }
}

.nu-form {
    input,
    textarea {
        border-bottom:  $nu-border-light-purple;
        margin-left:    10px;
        margin-right:   10px;
        padding:        5px;
        
        &:hover,
        &:focus {
            border-bottom: $nu-border-light-purple;
        }

        &.no-border {
            border: 0;
        }
    }
}

.nu-form-section {
    margin-bottom: 50px;
}

.nu-form-item {
    margin-bottom:  25px;
    position:       relative;
}

.nu-form-input-icon {
    width: 35px;
    
    a {
        height: 30px;
    }
}

.nu-form-label {
    font-size:  $nu-small-font-size;
    color:      $nu-secondary-font-color;
}

// ---------
// Checkbox
// ---------
// FIXME - Change the parent name to nu-form-checkbox
// Add the same class name to the @creately/ngx-treeview component.
.form-check input,
.form-custom-checkbox {
    position:   absolute;
    top:        0;
    left:       0;
    height:     $nu-form-checkbox-size;
    width:      $nu-form-checkbox-size;
}

.form-check input {
    opacity:  0;
    cursor:   pointer;
    z-index:  1;
}

.form-custom-checkbox {
    border:        $nu-border-light-purple;
    border-radius: 100%;
}
  
.form-check-label {
    padding-left:   20px;
    font-size:      $nu-base-font-size;
}

.form-check:hover {
    .form-check-label {
        color: $nu-btn-hover;
    }
}