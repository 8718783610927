/*
* This stylesheet contains a set of generic buttons.
*
* Usage:
* Standard button:
* <button class="fx-button"></button>
*
* Icon button:
* <button class="fx-button fx-icon"></button>
*
* Floating button:
* <button class="fx-button fx-floating"></button>
* <button class="fx-button fx-floating left"></button>
* <button class="fx-button fx-floating right"></button>
*
* Circular button:
* <button class="fx-button fx-round"></button>
*/

@import "./variables.scss";

$button-round-size: 45px;

@mixin button-color-generator( $map ) {
    @each $name, $value in $map {
        @if (lightness($value) > 50) {
            .fx-button.#{$name} {
                &:hover {
                    background-color: darken($value, 5%);
                }
    
                &:active {
                    background-color: darken($value, 10%);
                }
            }
        } @else {
            .fx-button.#{$name} {
                &:hover {
                    background-color: lighten($value, 5%);
                }
    
                &:active {
                    background-color: lighten($value, 10%);
                }
            }
        }
    
        .fx-button.fx-outline.#{$name} {
            border: 1px solid $value;
            color:  $value;
    
            &:hover {
                background-color:   $value;
                color:              white;
            }
    
            &:focus {
                background-color:   transparent;
                color:              $value;
            }
        }
    }
}

.fx-button {
    transition:         background-color 0.5s ease;
    border:             none;
    text-align:         center;
    padding-left:       5px;
    padding-right:      5px;
    cursor:             pointer;

    &.fx-block {
        border-radius:  5px;
        display:        block; 
    }

    &.fx-outline {
        background-color:   transparent;
    }

    &:focus {
        outline: none;
    }

    &.fx-icon {
        height:             100%;
        background-color:   transparent;
    }

    img {
        border: none;
    }

    &.fx-floating {
        text-align: center;

        &.top {
            top: 10px;
        }

        &.fx-right {
            right: 15px;
        } 

        &.fx-left {
            left: 15px;
        }
    }

    &.fx-round {
        width:      $button-round-size;
        height:     $button-round-size;
    }
}