/*
* This stylesheet contains styles used in the uppy file importer ui.
* Any styling overrides we do to the default Uppy styling must be done
* here.
*/

@import "~@uppy/dashboard/dist/style.css";
@import "~@uppy/core/dist/style.css";
@import "~flux-core/src/ui/styles/revamp/abstracts/variables";
@import "~flux-core/src/ui/styles/revamp/abstracts/util-mixins";

.uppy-Dashboard-poweredBy {
    display: none !important;
}

.uppy-DashboardContent-back {
    color: $primary !important;

    &:hover {
        color: getColor(primary) !important;
    }
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
    background-color: $primary !important;

    &:hover {
        background-color: getColor(primary) !important;
    }
}

.uppy-size--md .uppy-Dashboard-note {
    font-size: getScale("3.5") !important;
}

.uppy-Root *:focus {
    outline: none !important;
    border-bottom: none !important;
}

.uppy-Dashboard-browse:focus {
    outline: none !important;
    border: 1px solid $primary !important;
}

.uppy-StatusBar-actions {
    align-items:      flex-end !important;
    flex-direction:   column;
    justify-content:  center;
}

.uppy-Dashboard-browse {
    padding-top: getScale("2") !important;
    padding-right: getScale("2.5") !important;
    padding-bottom: getScale("2") !important;
    padding-left: getScale("2.5") !important;
    border: 1px solid getColor(primary, 'dark') !important;
    background-color: getColor(primary,'dark') !important;
    color: $primary-complement !important;
    border-radius: $default-border-radius;
    margin-top: getScale("2.5") !important;
    text-transform: capitalize;
    font-weight: normal !important;
    font-size: getScale("6") !important;

    &:hover {
        color: $primary-complement !important;
        background-color: getColor(primary) !important;
        border: 1px solid getColor(primary) !important;
    }
}

.uppy-container{
    @include font("sans", "normal", "3.5");
}

.uppy-Dashboard-innerWrap{
    @include font("sans", "normal", "3.5");
}