/*
* This file contains generic styles which are common to all icons in the app.
*/

$nu-icons: (
    xsmall: 15px,
    small: 20px,
    med: 25px,
    large: 45px,
    xlarge: 70px,
);

.nu-icon {
    // Icons should be created in a 30x30 artboard
    display:        inline-block;
    width:          30px; 
    height:         30px;
    stroke-width:   0;
    stroke:         currentColor;
    fill:           currentColor;
    color:          $primary-icon-color;
}

.nu-icon-primary-fill {
    fill:    $primary-icon-color;
}

@each $name, $value in $nu-colormap {
    .nu-icon.#{$name} {
        color: $value;
    }
}

@each $name, $value in $nu-icons {
    .nu-icon-#{$name} {
        width: $value;
        height: $value;
    }
}