@import "../../../../node_modules/flux-core/src/ui/creately-theme/variables.scss";
@import "../../../../node_modules/flux-core/src/ui/styles/revamp/abstracts/variables";
@import "../../../../node_modules/flux-core/src/ui/styles/revamp/abstracts/util-mixins";
@import "../../../../node_modules/flux-core/src/ui/styles/revamp/abstracts/util-fns";

.ProseMirror {
    position: relative;
}

.ProseMirror {
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: break-spaces;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
}

.ProseMirror [contenteditable="false"] {
    white-space: normal;
}

.ProseMirror [contenteditable="false"] [contenteditable="true"] {
    white-space: pre-wrap;
}

.ProseMirror pre {
    white-space: pre-wrap;
}

img.ProseMirror-separator {
    display: inline !important;
    border: none !important;
    margin: 0 !important;
    width: 1px !important;
    height: 1px !important;
}

.ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;
    margin: 0;
}

.ProseMirror-gapcursor:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    border-top: 1px solid black;
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
    to {
        visibility: hidden;
    }
}

.ProseMirror-hideselection *::selection {
    background: transparent;
}

.ProseMirror-hideselection *::-moz-selection {
    background: transparent;
}

.ProseMirror-hideselection * {
    caret-color: transparent;
}

.ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
}

.tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
}

.prose {
	color: #374151;
	margin: 0px !important;
	font-size: 1rem;
	line-height: 1.75;
    word-spacing: 0px;
    color: 'thisun2';
}

.prose [class~="lead"] {
	color: #4b5563;
	font-size: 1.25em;
	line-height: 1.6;
	margin-top: 1.2em;
	margin-bottom: 1.2em;
}

.prose a {
	color: #111827;
	text-decoration: underline;
	font-weight: 500;
}

.prose strong {
	font-weight: 600;
}

.prose ol[type="A"] {
	--list-counter-style: upper-alpha;
}

.prose ol[type="a"] {
	--list-counter-style: lower-alpha;
}

.prose ol[type="I"] {
	--list-counter-style: upper-roman;
}

.prose ol[type="i"] {
	--list-counter-style: lower-roman;
}

.prose ol[type="1"] {
	--list-counter-style: decimal;
	font-size: getScale("3.5");
}

.prose ol {
	font-size: getScale("3.5");
}

.prose ol > li {
	position: relative;
}

.prose ul > li {
	position: relative;
}

.prose hr {
	border-color: #e5e7eb;
	border-top-width: 1px;
	margin-top: 3em;
	margin-bottom: 3em;
}

.prose blockquote {
	font-weight: 500;
	font-style: italic;
	color: #111827;
	border-left-width: 0.25rem;
	border-left-color: #e5e7eb;
	margin-top: 1.6em;
	margin-bottom: 1.6em;
	padding-left: 1em;
}

.prose h1 {
	@include font("sans", "semibold", "8");
	@include leading("9");
	color: getColor(black);
	@include margin-top("0");
	@include margin-bottom("5");
}

.prose h2 {
	@include font("sans", "medium", "6");
	@include leading("8");
	color: getColor(black);
	@include margin-top("5");
	@include margin-bottom("3.5");
}

.prose h3 {
	@include font("sans", "medium", "4");
	@include leading("6");
	color: getColor(black);
	@include margin-top("5");
	@include margin-bottom("1");
}

.prose figure figcaption {
	color: #6b7280;
	font-size: 0.875em;
	line-height: 1.4285714;
	margin-top: 0.8571429em;
}

.prose code {
	color: #111827;
	font-weight: 600;
	font-size: 0.875em;
}

.prose a code {
	color: #111827;
}

.prose pre {
	color: #e5e7eb;
	background-color: #1f2937;
	overflow-x: auto;
	font-size: 0.875em;
	line-height: 1.7142857;
	margin-top: 1.7142857em;
	margin-bottom: 1.7142857em;
	border-radius: 0.375rem;
	padding-top: 0.8571429em;
	padding-right: 1.1428571em;
	padding-bottom: 0.8571429em;
	padding-left: 1.1428571em;
}

.prose pre code {
	background-color: transparent;
	border-width: 0;
	border-radius: 0;
	padding: 0;
	font-weight: 400;
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

.prose pre code::before {
	content: none;
}

.prose pre code::after {
	content: none;
}

.prose table {
	text-align: left;
	font-size: 0.875em;
	line-height: 1.7142857;
	margin: 12px 0;
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	overflow: hidden;
}

.prose thead {
	color: #111827;
	font-weight: 600;
	border-bottom-width: 1px;
	border-bottom-color: #d1d5db;
}

.prose thead th {
	vertical-align: bottom;
	padding-right: 0.5714286em;
	padding-bottom: 0.5714286em;
	padding-left: 0.5714286em;
}

.prose tbody tr {
	border-bottom-width: 1px;
	border-bottom-color: #e5e7eb;
}

.prose tbody tr:last-child {
	border-bottom-width: 0;
}

.prose tbody td {
	vertical-align: top;
	padding-top: 0.5714286em;
	padding-right: 0.5714286em;
	padding-bottom: 0.5714286em;
	padding-left: 0.5714286em;
}

.prose p {
	@include font("sans", "normal", "3.5");
	@include leading("6");
	color: getColor(grey, 'xx-dark');
	@include margin-y("1");
}

.prose img {
	margin-top: 2em;
	margin-bottom: 2em;
}

.prose video {
	margin-top: 2em;
	margin-bottom: 2em;
}

.prose figure {
	margin-top: 2em;
	margin-bottom: 2em;
}

.prose figure > * {
	margin-top: 0;
	margin-bottom: 0;
}

.prose h2 code {
	font-size: 0.875em;
}

.prose h3 code {
	font-size: 0.9em;
}

.prose li {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.prose > ul > li p {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.prose > ul > li > :first-child {
	margin-top: 1.25em;
}

.prose > ul > li > :last-child {
	margin-bottom: 1.25em;
}

.prose > ol > li > :first-child {
	margin-top: 1.25em;
}

.prose > ol > li > :last-child {
	margin-bottom: 1.25em;
}

.prose ol ol, .prose ol ul, .prose ul ol, .prose ul ul {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
}

.prose thead th:first-child {
	padding-left: 0;
}

.prose thead th:last-child {
	padding-right: 0;
}

.prose tbody td:first-child {
	padding-left: 5px;
}

.prose tbody td:last-child {
	padding-right: 0;
}

.prose > :first-child {
	margin-top: 0;
}

.prose > :last-child {
	margin-bottom: 0;
}

.prose > * + * {
	margin-top: 0.75em;
}

.prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6 {
	line-height: 1.1;
    margin: 0 0 12px 0 !important;
}

.prose h1 *, .prose h2 *, .prose h3 *, .prose h4 * {
    font-size: inherit;
}

.prose .collaboration-cursor__label {
    font-size: getScale( '3' ) !important;
}

.prose code {
	background-color: #6161611a;
	color: #616161;
}

.prose pre {
	background: #0D0D0D;
	color: #fff;
	font-family: JetBrainsMono, monospace;
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
}

.prose pre code {
	color: inherit;
	padding: 0;
	background: none;
	font-size: 0.8rem;
}

.prose img {
	height: auto;
}

.prose blockquote {
	padding-left: 1rem;
	border-left: 2px solid rgb(13, 13, 13, 0.1);
}

.prose hr {
	border: none;
	border-top: 2px solid rgb(13, 13, 13, 0.1);
	margin: 2rem 0;
}

.prose table td, .prose table th {
	min-width: 1em;
	border: #ced4da solid 2px;
	padding: 3px 5px;
	vertical-align: top;
	box-sizing: border-box;
	position: relative;
}

.prose table td > *, .prose table th > * {
	margin-bottom: 0;
}

.prose table th {
	font-weight: 700;
	text-align: left;
	background-color: #f1f3f5;
}

.prose table .selectedCell:after {
	z-index: 2;
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgb(200, 200, 255, 0.4);
	pointer-events: none;
}

.prose table .column-resize-handle {
	position: absolute;
	right: -2px;
	top: 0;
	bottom: -2px;
	width: 4px;
	background-color: #adf;
	pointer-events: none;
}

.prose table p {
	margin: 0;
}

.prose .tableWrapper {
	overflow-x: auto;
}

.prose .resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}


/* CSS for Collapsible section*/
.prose > * + * {
	margin-top: 0.75em;
}

.prose .is-empty:before {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}

.prose .details {
	display: flex;
	margin: 1rem 0;
	border: black solid 1px;
	border-radius: 0.5rem;
	padding: 0.5rem;
}

.prose .details > button {
	display: flex;
	cursor: pointer;
	background: transparent;
	border: none;
	padding: 0;
}

.prose .details > button:before {
	content: "\25b6";
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.5em;
	height: 1.5em;
}

.prose .details.is-open > button:before {
	content: "\25bc";
}

.prose .details > div {
	flex: 1 1 auto;
}

.prose .details :last-child {
	margin-bottom: 0;
}


/* Give a remote user a caret */
.prose .collaboration-cursor__caret {
	border-left: 1px solid #0d0d0d;
	border-right: 1px solid #0d0d0d;
	margin-left: -1px;
	margin-right: -1px;
	pointer-events: none;
	position: relative;
	word-break: normal;
}

/* Render the username above the caret */
.prose .collaboration-cursor__label {
	border-radius: 3px 3px 3px 0;
	color: white;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	left: -1px;
	line-height: normal;
	padding: 0.1rem 0.3rem;
	position: absolute;
	top: -1.4em;
	user-select: none;
	white-space: nowrap;
}

.prose p.is-empty.is-editor-empty {
	margin-top: 0;
	position: absolute;
}

.prose ul[data-type="taskList"] li::before {
	font-size: 0 !important;
}

.prose ul[data-type="taskList"] li label {
	display: flex !important;
	margin-top: 1px !important;
}

.prose li p {
	margin: 0 !important;
}

.prose img {
	margin-top: 10px !important;
	margin-bottom: 0 !important;
}

.prose-selectednode {
	outline: 2px solid #8cf;
}

.prose ul[data-type="taskList"] {
	list-style: none;
	padding: 0;
}

.prose ul[data-type="taskList"] p {
	margin: 0;
}

.prose ul[data-type="taskList"] li {
	display: flex;
}

.prose ul[data-type="taskList"] li > label {
	flex: 0 0 auto;
	margin-right: 0.5rem;
	user-select: none;
}

.prose ul[data-type="taskList"] li > div {
	flex: 1 1 auto;
}

.prose ol, .prose ul {
	padding: 0 0 0 25px;
	margin-top: 12px;
	margin-bottom: 12px;
}

.prose ul[data-type=taskList] li {
	padding-left: 0 !important;
}

.prose ul[data-type=taskList] li > div {
	margin-bottom: 0px !important;
}

.prose ul[data-type=taskList] li {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.prose em, .prose s, .prose strong, .prose u, .prose span {
    font-size: inherit;
    font-family: inherit;
}

.prose div[data-type=details] {
    display: flex;
    margin: 1rem 0;
    border: 1px solid black;
    border-radius: .5rem;
    padding: .5rem
}

.prose div[data-type=details]>button {
    display: flex;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0
}

.prose div[data-type=details]>button:before {
    content: "\25b6";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em
}

.prose div[data-type=details].is-open>button:before {
    content: "\25bc"
}

.prose div[data-type=details]>div {
    flex: 1 1 auto
}

.prose div[data-type=details] :last-child {
    margin-bottom: 0
}


.prose div[data-type=primaryTextNode][showbutton="false"] button {
    display: none;
}

.prose div[data-type=primaryTextNode] {
    display: flex;
    margin-bottom: 1rem;
}

.prose div[data-type=primaryTextNode] summary {
	@include font("sans", "semibold", "8");
	@include leading("9");
	color: getColor(black);
	@include margin-top("0");
}

.prose div[data-type=primaryTextNode] summary[inactive=true] {
	white-space: nowrap;
    overflow: hidden;
    width: 473px;
    display: inline;
    text-overflow: ellipsis;
}

.prose div[data-type=primaryTextNode] summary span {
	font-size: unset !important;
}

.prose div[data-type=primaryTextNode]>button {
    width: 513px;
    height: 42px;
    display: flex;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
	position: absolute;
	right: 0px;
    top: 0px;
    font-size: 12px;
    color: getColor(grey);
}

.prose div[data-type=primaryTextNode] div[data-type="detailsContent"] > * {
    text-align: left !important;
}

.prose div[data-type=primaryTextNode] div[data-type="detailsContent"][dir="rtl"] > * {
    text-align: right !important;
}



.prose div[data-type=primaryTextNode]>div {
    flex: 1 1 auto
}

.prose div[data-type=primaryTextNode] :last-child {
    margin-bottom: 0
}


.prose summary {
    display: unset;
}

.prose span[data-type="mention"] {
    color: getColor('link');
}
