/*
* This file contains styles which are specific to the coming soon
* tooltip.
*
* Styles that are common to all tooltips are added into tooltip.scss.
* Classes or variables in this file will have the prefix "coming-soon-"
*/

@import "../styles/flux.ui.scss";

$coming-soon-header-bg:         darken(#444B58, 2% );
$coming-soon-header-ribbon-bg:  darken( $coming-soon-header-bg, 20% );
$coming-soon-ribbon-txt-color:  map-get( $fx-colors , "yellow" );
$coming-soon-font-size:         $fx-font-size-small + 1;

.coming-soon-tooltip-container {
    min-width:  200px;
    padding:    0;

    .coming-soon-feature-text {
        padding:        10px;
        line-height:    18px;
        font-size:      $coming-soon-font-size;
    }
}

.coming-soon-header {
    display:            flex;
    background-color:   $coming-soon-header-bg;
    border-radius:      5px 5px 0 0;

    span {
        flex:           1;
        padding:        5px 5px 5px 10px;
        font-weight:    bold;
    }

    .coming-soon-header-ribbon {
        position:           relative;
        border-radius:      0 5px 0 0;
        padding:            5px 10px 5px 10px;
        background-color:   $coming-soon-header-ribbon-bg;
        color:              $coming-soon-ribbon-txt-color;
        font-size:          $coming-soon-font-size;

        &:before {
            content:        "";
            width:          10px;
            position:       absolute;
            background:     $coming-soon-header-ribbon-bg;
            top:            0;
            left:           -5px;
            bottom:         0;
            transform:      skew(-20deg);
        }
    }
}