/*
* This file contains the generic styles for the modal windows.
*
* Classes or variables declared in this file will have the prefix
* 'modal-window-'.
*/

@import "~flux-core/src/ui/creately-theme/variables.scss";
@import "~flux-core/src/ui/styles/revamp/abstracts/_util-mixins.scss";
@import "../styles/revamp/abstracts/_variables.scss";
@import "~flux-core/src/ui/styles/revamp/abstracts/util-fns";

.modal-window-container {
    z-index:            $zindex-modal-window;
    background-color:   getColorOpacity(black, "base", "8");
}

.modal-window-container-no-bg {
    z-index:            $zindex-modal-window;
    background-color:   transparent;
}

.modal-window-inner {
    @include padding("3.5");
    border-radius:      $default-border-radius;
    position:           relative;
    background-color: $default-container-bg;
}

.modal-window-heading {
    @include padding-top("2.5");
    @include padding-bottom("5");
    display:        flex;
    align-items:    center;
    text-align:     left;

    button {
        position: absolute;
        right: getScale("1");
    }
    .nu-icon{
        &:hover{
            color: getColor(primary, 'dark');
        }
    }
}