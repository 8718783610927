/********************************************************************************
  This file provides mixins that access design tokens for you. This eliminates
     1. ambiguity in transforming a design into code
     2. improves scalability and maintainability of the system

  We adapted this 'Token Mixins' appraoch from Rangle. For More info
  Go to: https://rangle.io/blog/mixin-design-tokens/

  USAGE:
      .some-class-you-code {
          @include font("sans", "semibold");
          @include padding-left("2");
          @include margin-top("0.5");
      }
*********************************************************************************/

@import "tokens";
@import "./util-fns";

/**padding**/
@mixin padding($size, $sign: "+") {
    padding: getScale($size, $sign);
}
@mixin padding-top($size, $sign: "+") {
    padding-top: getScale($size, $sign);
}
@mixin padding-bottom($size, $sign: "+") {
    padding-bottom: getScale($size, $sign);
}
@mixin padding-left($size, $sign: "+") {
    padding-left: getScale($size, $sign);
}
@mixin padding-right($size, $sign: "+") {
    padding-right: getScale($size, $sign);
}
@mixin padding-x($size, $sign: "+") {
    padding-left: getScale($size, $sign);
    padding-right: getScale($size, $sign);
}
@mixin padding-y($size, $sign: "+") {
    padding-top: getScale($size, $sign);
    padding-bottom: getScale($size, $sign);
}

/**margin**/
@mixin margin($size, $sign: "+") {
    margin: getScale($size, $sign);
}
@mixin margin-top($size, $sign: "+") {
    margin-top: getScale($size, $sign);
}
@mixin margin-bottom($size, $sign: "+") {
    margin-bottom: getScale($size, $sign);
}
@mixin margin-left($size, $sign: "+") {
    margin-left: getScale($size, $sign);
}
@mixin margin-right($size, $sign: "+") {
    margin-right: getScale($size, $sign);
}
@mixin margin-x($size, $sign: "+") {
    margin-left: getScale($size, $sign);
    margin-right: getScale($size, $sign);
}
@mixin margin-y($size, $sign: "+") {
    margin-top: getScale($size, $sign);
    margin-bottom: getScale($size, $sign);
}

/**color**/
@mixin text-color($color, $size: "base") {
    color: getColor($color, $size);
}
/**background-color**/
@mixin background-color($color, $size: "base") {
    background-color: getColor($color, $size);
}

/**border**/
@mixin border($color, $size: "0.5px", $style: solid) {
    $thickness: getScale($size);
    border: $thickness $style $color;
}

/**opacity**/
@mixin opacity($opacity: "50") {
    $opacityTokens: map-get($tokens, "opacity");
    opacity: map-get($opacityTokens, $opacity);
}

/*******************/
/*****Typography****/
/*******************/
$fontFamilyTokens: map-get($tokens, "fontFamily");
$textWeightTokens: map-get($tokens, "textWeight");
$leadingTokens: map-get($tokens, "leading");
$trackingTokens: map-get($tokens, "tracking");
$textTokens: map-get($tokens, "textStyle");

@mixin font($family, $weight: "normal", $size: "4") {
    font-family: map-get($fontFamilyTokens, $family);
    font-size: getScale($size);
    font-weight: map-get($textWeightTokens, $weight);
}

@mixin leading($spacing) {
    line-height: map-get($leadingTokens, $spacing);
}

@mixin tracking($spacing) {
    letter-spacing: map-get($trackingTokens, $spacing);
}

@mixin text($alignment, $transform: "normal-case") {
    $alignTokens: map-get($textTokens, "align");
    $transformTokens: map-get($textTokens, "transform");
    text-align: map-get($alignTokens, $alignment);
    text-transform: map-get($transformTokens, $transform);
}

/*** Box Shadow Mixin ***/
@mixin box-shadow($level) {
    $shadow: map-get($boxShadowMap, $level);
    $primary-dimension: map-get($shadow, "dimension");
    $secondary-dimension: map-get($shadow, "dimension-secondary");
    $primary-color: rgba(getColor(black), map-get($shadow, "opacity"));
    $secondary-color: rgba(getColor(black), map-get($shadow, "opacity-secondary"));
    box-shadow: $primary-dimension $primary-color, $secondary-dimension $secondary-color;
}

/*** Box Shadow Inset ***/
@mixin box-shadow-inset($level) {
    $shadow: map-get($boxShadowMap, $level);
    box-shadow: map-get($shadow, "inset-dimension") rgba(getColor(black), map-get($shadow, "opacity"));
}