/* 
* This file includes global styles which affects all parts of nucleus and proton.
* Styles which are specific to each app should be added into it's respective global stylesheet.
*/

@import "./variables.scss";
@import "./buttons.scss";
@import "./icons.scss";
@import "./loaders.scss";
@import "./animations.scss";
@import "./modal-window.scss";
@import "./forms.scss";
@import "./typography.scss";
@import "../tooltip/tooltip.scss";
@import "../floating-container/floating-container.scss";

@import "../styles/flux.ui.scss";
@import "../styles/buttons.scss";
@import "../styles/reset.scss";

@import '~ngx-ui-switch/ui-switch.component.scss';


* {
    user-select: none;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
    @include color-class-generator($nu-colormap);
    color:  $nu-primary-font-color;
    overflow: hidden;
}

h1 {
    font-size:  $nu-base-font-size;
    margin:     0;
}

h1, h2, h3, h4 {
    font-weight: normal;
    font-family: $nu-heading-font-family;
}

label {
    font-size: $nu-small-font-size;
}

a {
    cursor:             pointer;
    text-decoration:    none;

    &:hover, &:hover .nu-icon {
        color: $nu-btn-hover;
    }
}

p {
    line-height:  20px;
}

label, span, p, a, 
h1, h2, h3, h4, 
input, textarea,
div[contenteditable=true] {
    user-select: text;
}

@each $name, $value in $nu-colormap {
  a {
      &.#{$name} {
        &:hover, &:hover .nu-icon {
            color: $nu-btn-hover;
        }
      }
  }
}

// Facebook iFrame issue
#facebook-jssdk-iframe {
    display: none;
}

// Blur effect
.nu-overlay-blur,
router-outlet.nu-overlay-blur:first-of-type + * {
    filter: blur( 5px );
    transform: translateZ(0);
}

// Text
.nu-text-primary {
    font-size: $nu-base-font-size;
}

.nu-text-xsmall {
    font-size: $nu-xsmall-font-size;
}

.nu-text-small {
    font-size: $nu-small-font-size;
}

.nu-text-medium {
    font-size: $nu-medium-font-size;
}

.nu-text-bold {
    font-family: $nu-bold-font-family;
}

// Shadow
.has-shadow {
    box-shadow:  $nu-component-shadows;
}