/**
* This file contains styles which are specific to the Flux UI
* tooltip.
*
* Any class or variable declared in this file will have the prefix "tooltip-".
*/

@import "../styles/flux.ui.scss";
@import "../creately-theme/variables.scss";
@import "../styles/revamp/abstracts/variables";
@import "~flux-core/src/ui/styles/revamp/abstracts/util-fns";
@import "~flux-core/src/ui/styles/revamp/abstracts/util-mixins";


$tooltip-border-radius: $default-border-radius !default;
$tooltip-max-width:     250px !default;
$tooltip-arrow-size:    5px;

.tooltip {
    @include padding("2.5");
    position:           absolute;
    z-index:            $zindex-tooltip;
    max-width:          $tooltip-max-width;
    background-color:   $tooltip-bg-color;
    border-radius:      $tooltip-border-radius;
    color:              $tooltip-font-color;

    &> * {
        display: block;
    }
}

$tooltip-arrows: (
    bottom: up,
    top: down,
    left: right,
    right: left,
);

.tooltip-arrow {
    position: absolute;
    z-index:  $zindex-tooltip;

    @each $name, $direction in $tooltip-arrows {
        &.#{$name} {
            @include create-triangle( #{$direction}, $tooltip-arrow-size, $tooltip-bg-color );
        }
    }
}

.tooltip-header {
    @include leading("6");
    font-size: getScale("3.5");
}

.tooltip-body {
    @include margin-top("1");
    line-height: getScale("3.5");
    font-size: calc(#{getScale("2.5")} + #{getScale("px")});
}

.tooltip-footer {
    @include margin-top("1");
    line-height: getScale("3.5");
    font-size: calc(#{getScale("2.5")} + #{getScale("px")});
    color: #81949d;
}

.tooltip-content {
    @include leading("4");
    font-size: getScale("3");

    // Tooltip with shortcut and label
    .tooltip-label {
        @include margin-right("4");
    }
    .tooltip-shortcut {
        @include opacity();
    }
}

// Overrides the default tooltip styles.
@import "./coming-soon.scss";