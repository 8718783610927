/********************************************************************************
  This file contains new SCSS classes related to typography with the
  adaption of mixin-tokens approach. Note the use of util-fns.scss and
  util-mixins.scss. We encourage you to adapt importing mixins and functions
  provided by design system to access design tokens when writing CSS rules.
*********************************************************************************/

@import "../abstracts/util-fns";
@import "../abstracts/util-mixins";

/*** TYPOGRAPHY 2022 ***/
/*** USAGE: class="title title--md title--bold" ***/
.title {
    @include font("sans", "normal", "5");
    @include leading("8");

    &--md {
        font-size: calc(#{getScale("5")} + #{getScale("0.5")});
    }

    &--lg {
        font-size: getScale("7");
    }

    &--xl {
        font-size: calc(#{getScale("8")} + #{getScale("0.5")});
        @include leading("7");
    }

    &--semibold {
        font-weight: getWeight("medium");
    }

    &--bold {
        font-weight: getWeight("semibold");
    }
}

/*** USAGE: class="body" ***/
.body {
    @include font("sans", "normal", "3.5");
    @include leading("6");

    &--lg {
        font-size: getScale("4");
    }

    &--bold {
        font-weight: getWeight("medium");
    }
}

/*** USAGE: class="caption caption--sm" ***/
.caption {
    @include font("sans", "normal", "3");
    @include leading("4");

    &--sm {
        font-size: calc(#{getScale("2.5")} + #{getScale("px")});
        line-height: calc(#{getScale("3")} + #{getScale("px")});
    }

    &--bold {
        font-weight: getWeight("medium");
    }
}

/*** USAGE: class="overline" ***/
.overline {
    @include font("sans");
    @include tracking("wider");
    @include text("normal", "uppercase");
    font-size: calc(#{getScale("2")} + #{getScale("px")});

    &--lg {
        font-size: calc(#{getScale("2.5")} + #{getScale("px")});
    }

    &--bold {
        font-weight: getWeight("medium");
    }
}


/***
Following classes are created to reflect exisitng heading levels,
captions and body text for compatibility. one must not confuse these classes with
typorgraphy classes above. we might use following classes in tiptap editor
otherwise we will remove these rules based on usage/utilization

TODO: FINALIZE WITH UX TEAM TO FINE-TUNE ABOVE CLASSES SO THAT WE CAN REMOVE
        FOLLOWING CLASSES WHILE HAVING NECESSARY STYLES ABSTRACTED INTO ABOVE
        MAIN TYPOGRAPHY STYLES
***/
.heading {
    @include font("sans");

    &--title {
        font-size: getScale("24");
        @include leading("24");
        @include tracking("tighter");
    }

    &--subtitle {
        font-size: getScale("12");
        font-weight: getWeight("semibold");
        @include leading("16");
        @include tracking("tight");
    }

    /*** Heading Levels ***/
    &--l1 {
        font-size: getScale("16");
        @include leading("18");
        @include tracking("tight");
    }

    &--l2 {
        font-size: getScale("12");
        @include leading("12");
        @include tracking("normal");
    }

    &--l3 {
        font-size: getScale("9");
        @include leading("11");
        @include tracking("normal");
    }

    &--l4 {
        font-weight: getWeight("semibold");
        font-size: getScale("6");
        @include leading("9");
        @include tracking("tight");
    }

    &--l5 {
        font-weight: getWeight("semibold");
        font-size: getScale("5");
        @include leading("6");
        @include tracking("wide");
    }

    &--l6 {
        font-size: getScale("4");
        @include leading("6");
        @include tracking("normal");
    }

    /*** Sub-Heading Levels ***/
    &--subheading-1 {
        font-size: getScale("8");
        font-weight: getWeight("light");
        @include leading("10");
        @include tracking("tighter");
    }

    &--subheading-2 {
        font-size: getScale("6");
        @include leading("8");
        @include tracking("normal");
    }

    &--subheading-3 {
        font-size: getScale("5");
        @include leading("8");
        @include tracking("normal");
    }

    &--subheading-4 {
        font-size: getScale("4.5");
        @include leading("8");
        @include tracking("normal");
    }

    &--subheading-5 {
        font-size: getScale("4");
        @include leading("8");
        @include tracking("normal");
    }
}

.prose {
    @include font("sans-serif");
    /* check default letter spacing */
    /* check default line height */

    /*** Body Text ***/
    &--lg {
        font-size: getScale("4.5");
    }

    &--md {
        font-size: getScale("3.5");
        @include leading("5");
    }

    &--sm {
        font-size: calc(#{getScale("3")} + #{getScale("px")});
        @include leading("5");
    }

    &--xs {
        font-size: getScale("3");
        @include leading("4");
    }

    /*** Body Caption ***/
    &__caption {
        @include leading("5");
        @include tracking("normal");

        &--l1 {
            font-size: getScale("3.5");
        }

        &--l2 {
            font-size: calc(#{getScale("3")} + #{getScale("px")});
            font-weight: getWeight("bold");
        }

        &--l3 {
            font-size: calc(#{getScale("3")} + #{getScale("px")});
            font-weight: getWeight("semibold");
        }

        &--l4 {
            font-size: getScale("3");
            font-weight: getWeight("semibold");
            @include leading("4");
        }

        &--l5 {
            font-size: calc(#{getScale("2.5")} + #{getScale("px")});
            font-weight: getWeight("semibold");
            @include leading("4");
        }

        &--l6 {
            font-size: calc(#{getScale("2.5")} + #{getScale("px")});
            @include leading("4");
        }
    }
}
